export const MenuData = [
  {
    title: "Home",
    url: "/",
  },
  {
    title: "About",
    url: "/About",
  },
  {
    title: "Contact Us",
    url: "/Contact",
  },
];
