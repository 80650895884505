export const chartData: number[] = [
  1, 1, 1, 1, 1, 1, 1, 1, 1, 1, 1, 1, 1, 1, 1, 1, 1, 1, 1, 1, 1, 1, 1, 1, 1, 1,
  1, 1, 1, 1, 1, 1, 1, 1, 1, 1, 1, 1, 1, 1, 1, 1, 1, 1, 1, 1, 1, 1, 1, 1, 1, 1,
  1, 1, 1, 1, 1, 1, 1, 1, 1, 1, 1, 1, 1, 1, 1, 1, 1, 1, 1, 1, 1, 1, 1, 1, 1, 1,
  1, 1, 1, 1, 1, 1, 1, 1, 1, 1, 1, 1, 1, 1, 1, 1, 1, 1, 1, 1, 1, 1, 1, 1, 1, 1,
  1, 1, 1, 1, 1, 1, 1, 1, 1, 1, 1, 1, 1, 1, 1, 1, 1, 1, 1, 1, 1, 1, 1, 1, 1, 1,
  1, 1, 1, 1, 1, 1, 1, 1, 1, 1, 1, 1, 1, 1, 1, 1, 1, 1, 1, 1, 1, 1, 1, 1, 1, 1,
  1, 1, 1, 1, 1, 1, 1, 1, 1, 1, 1, 1, 1, 1, 1, 1, 1, 1, 1, 1, 1, 1, 1, 1, 1, 1,
  1, 1, 1, 1, 1, 1, 1, 1, 1, 1, 1, 1, 1, 1, 1, 1, 1, 1, 1, 1, 1, 1, 1, 1, 1, 1,
  1, 1, 1, 1, 1, 1, 1, 1, 1, 1, 1, 1, 1, 1, 1, 1, 1, 1, 1, 1, 1, 1, 1, 1, 1, 1,
  1, 1, 1, 1, 1, 1, 1, 1, 1, 1, 1, 1, 1, 1, 1, 1, 1, 1, 1, 1, 1, 1, 1, 1, 1, 1,
  1, 1, 1, 1, 1, 1, 1, 1, 1, 1, 1, 1, 1, 1, 1, 1, 1, 1, 1, 1, 1, 1, 1, 1, 1, 1,
  1, 1, 1, 1, 1, 1, 1, 1, 1, 1, 1, 1, 1, 1, 1, 1, 1, 1, 1, 1, 1, 1, 1, 1, 1, 1,
  1, 1, 1, 1, 1, 1, 1, 1, 1, 1, 1, 1, 1, 1, 1, 1, 1, 1, 1, 1, 1, 1, 1, 1, 1, 1,
  1, 1, 1, 1, 1, 1, 1, 1, 1, 1, 1, 1, 1, 1, 1, 1, 1, 1, 1, 1, 1, 1, 1, 1, 1, 1,
  1, 1, 1, 1, 1, 1, 1, 1, 1, 1, 1, 1, 1, 1, 1, 1, 1, 1, 1, 1, 1, 1, 1, 1, 1, 1,
  1, 1, 1, 1, 1, 1, 1, 1, 1, 1, 1, 1, 1, 1, 1, 1, 1, 1, 1, 1, 1, 1, 1, 1, 1, 1,
  1, 1, 1, 1, 1, 1, 1, 1, 1, 1, 1, 1, 1, 1, 1, 1, 1, 1, 1, 1, 1, 1, 1, 1, 1, 1,
  1, 1, 1, 1, 1, 1, 1, 1, 1, 1, 1, 1, 1, 1, 1, 1, 1, 1, 1, 1, 1, 1, 1, 1, 1, 1,
  1, 1, 1, 1, 1, 1, 1, 1, 1, 1, 1, 1, 1, 1, 1, 1, 1, 1, 1, 1, 1, 1, 1, 1, 1, 1,
  1, 1, 1, 1, 1, 1, 1, 1, 1, 1, 1, 1, 1, 1, 1, 1, 1, 1, 1, 1, 1, 1, 1, 1, 1, 1,
  1, 1, 1, 1, 1, 1, 1, 1, 1, 1, 1, 1, 1, 1, 1, 1, 1, 1, 1, 1, 1, 1, 1, 1, 1, 1,
  1, 1, 1, 1, 1, 1, 1, 1, 1, 1, 1, 1, 1, 1, 1, 1, 1, 1, 1, 1, 1, 1, 1, 1, 1, 1,
  1, 1, 1, 1, 1, 1, 1, 1, 1, 1, 1, 1, 1, 1, 1, 1, 1, 1, 1, 1, 1, 1, 1, 1, 1, 1,
  1, 1, 1, 1, 1, 1, 1, 1, 1, 1, 1, 1, 1, 1, 1, 1, 1, 1, 1, 1, 1, 1, 1, 1, 1, 1,
  1, 1, 1, 1, 1, 1, 1, 1, 1, 1, 1, 1, 1, 1, 1, 1, 1, 1, 1, 1, 1, 1, 1, 1, 1, 1,
  1, 1, 1, 1, 1, 1, 1, 1, 1, 1, 1, 1, 1, 1, 1, 1, 1, 1, 1, 1, 1, 1, 1, 1, 1, 1,
  1, 1, 1, 1, 1, 1, 1, 1, 1, 1, 1, 1, 1, 1, 1, 1, 1, 1, 1, 1, 1, 1, 1, 1, 1, 1,
  1, 1, 1, 1, 1, 1, 1, 1, 1, 1, 1, 1, 1, 1, 1, 1, 1, 1, 1, 1, 1, 1, 1, 1, 1, 1,
  1, 1, 1, 1, 1, 1, 1, 1, 1, 1, 1, 1, 1, 1, 1, 1, 1, 1, 1, 1, 1, 1, 1, 1, 1, 1,
  1, 1, 1, 1, 1, 1, 1, 1, 1, 1, 1, 1, 1, 1, 1, 1, 1, 1, 1, 1, 1, 1, 1, 1, 1, 1,
  1, 1, 1, 1, 1, 1, 1, 1, 1, 1, 1, 1, 1, 1, 1, 1, 1, 1, 1, 1, 1, 1, 1, 1, 1, 1,
  1, 1, 1, 1, 1, 1, 1, 1, 1, 1, 1, 1, 1, 1, 1, 1, 1, 1, 1, 1, 1, 1, 1, 1, 1, 1,
  1, 1, 1, 1, 1, 1, 1, 1, 1, 1, 1, 1, 1, 1, 1, 1, 1, 1, 1, 1, 1, 1, 1, 1, 1, 1,
  1, 1, 1, 1, 1, 1, 1, 1, 1, 1, 1, 1, 1, 1, 1, 1, 1, 1, 1, 1, 1, 1, 1, 1, 1, 1,
  1, 1, 1, 1, 1, 1, 1, 1, 1, 1, 1, 1, 1, 1, 1, 1, 1, 1, 1, 1, 1, 1, 1, 1, 1, 1,
  1, 1, 1, 1, 1, 1, 1, 1, 1, 1, 1, 1, 1, 1, 1, 1, 1, 1, 1, 1, 1, 1, 1, 1, 1, 1,
  1, 1, 1, 1, 1, 1, 1, 1, 1, 1, 1, 1, 1, 1, 1, 1, 1, 1, 1, 1, 1, 1, 1, 1, 1, 1,
  1, 1, 1, 1, 1, 1, 1, 1, 1, 1, 1, 1, 1, 1, 1, 1, 1, 1, 1, 1, 1, 1, 1, 1, 1, 1,
  1, 1, 1, 1, 1, 1, 1, 1, 1, 1, 1, 1, 1, 1, 1, 1, 1, 1, 1, 1, 1, 1, 1, 1, 1, 1,
  1, 1, 1, 1, 1, 1, 1, 1, 1, 1, 1, 1, 1, 1, 1, 1, 1, 1, 1, 1, 1, 1, 1, 1, 1, 1,
  1, 1, 1, 1, 1, 1, 1, 1, 1, 1, 1, 1, 1, 1, 1, 1, 1, 1, 1, 1, 1, 1, 1, 1, 1, 1,
  1, 1, 1, 1, 1, 1, 1, 1, 1, 1, 1, 1, 1, 1, 1, 1, 1, 1, 1, 1, 1, 1, 1, 1, 1, 1,
  1, 1, 1, 1, 1, 1, 1, 1, 1, 1, 1, 1, 1, 1, 1, 1, 1, 1, 1, 1, 1, 1, 1, 1, 1, 1,
  1, 1, 1, 1, 1, 1, 1, 1, 1, 1, 1, 1, 1, 1, 1, 1, 1, 1, 1, 1, 1, 1, 1, 1, 1, 1,
  1, 1, 1, 1, 1, 1, 1, 1, 1, 1, 1, 1, 1, 1, 1, 1, 1, 1, 1, 1, 1, 1, 1, 1, 1, 1,
  1, 1, 1, 1, 1, 1, 1, 1, 1, 1, 1, 1, 1, 1, 1, 1, 1, 1, 1, 1, 1, 1, 1, 1, 1, 1,
  1, 1, 1, 1, 1, 1, 1, 1, 1, 1, 1, 1, 1, 1, 1, 1, 1, 1, 1, 1, 1, 1, 1, 1, 1, 1,
  1, 1, 1, 1, 1, 1, 1, 1, 1, 1, 1, 1, 1, 1, 1, 1, 1, 1, 1, 1, 1, 1, 1, 1, 1, 1,
  1, 1, 1, 1, 1, 1, 1, 1, 1, 1, 1, 1, 1, 1, 1, 1, 1, 1, 1, 1, 1, 1, 1, 1, 1, 1,
  1, 1, 1, 1, 1, 1, 1, 1, 1, 1, 1, 1, 1, 1, 1, 1, 1, 1, 1, 1, 1, 1, 1, 1, 1, 1,
  1, 1, 1, 1, 1, 1, 1, 1, 1, 1, 1, 1, 1, 1, 1, 1, 1, 1, 1, 1, 1, 1, 1, 1, 1, 1,
  1, 1, 1, 1, 1, 1, 1, 1, 1, 1, 1, 1, 1, 1, 1, 1, 1, 1, 1, 1, 1, 1, 1, 1, 1, 1,
  1, 1, 1, 1, 1, 1, 1, 1, 1, 1, 1, 1, 1, 1, 1, 1, 1, 1, 1, 1, 1, 1, 1, 1, 1, 1,
  1, 1, 1, 1, 1, 1, 1, 1, 1, 1, 1, 1, 1, 1, 1, 1, 1, 1, 1, 1, 1, 1, 1, 1, 1, 1,
  1, 1, 1, 1, 1, 1, 1, 1, 1, 1, 1, 1, 1, 1, 1, 1, 1, 1, 1, 1, 1, 1, 1, 1, 1, 1,
  1, 1, 1, 1, 1, 1, 1, 1, 1, 1, 1, 1, 1, 1, 1, 1, 1, 1, 1, 1, 1, 1, 1, 1, 1, 1,
  1, 1, 1, 1, 1, 1, 1, 1, 1, 1, 1, 1, 1, 1, 1, 1, 1, 1, 1, 1, 1, 1, 1, 1, 1, 1,
  1, 1, 1, 1, 1, 1, 1, 1, 1, 1, 1, 1, 1, 1, 1, 1, 1, 1, 1, 1, 1, 1, 1, 1, 1, 1,
  1, 1, 1, 1, 1, 1, 1, 1, 1, 1, 1, 1, 1, 1, 1, 1, 1, 1, 1, 1, 1, 1, 1, 1, 1, 1,
  1, 1, 1, 1, 1, 1, 1, 1, 1, 1, 1, 1, 1, 1, 1, 1, 1, 1, 1, 1, 1, 1, 1, 1, 1, 1,
  1, 1, 1, 1, 1, 1, 1, 1, 1, 1, 1, 1, 1, 1, 1, 1, 1, 1, 1, 1, 1, 1, 1, 1, 1, 1,
  1, 1, 1, 1, 1, 1, 1, 1, 1, 1, 1, 1, 1, 1, 1, 1, 1, 1, 1, 1, 1, 1, 1, 1, 1, 1,
  1, 1, 1, 1, 1, 1, 1, 1, 1, 1, 1, 1, 1, 1, 1, 1, 1, 1, 1, 1, 1, 1, 1, 1, 1, 1,
  1, 1, 1, 1, 1, 1, 1, 1, 1, 1, 1, 1, 1, 1, 1, 1, 1, 1, 1, 1, 1, 1, 1, 1, 1, 1,
  1, 1, 1, 1, 1, 1, 1, 1, 1, 1, 1, 1, 1, 1, 1, 1, 1, 1, 1, 1, 1, 1, 1, 1, 1, 1,
  1, 1, 1, 1, 1, 1, 1, 1, 1, 1, 1, 1, 1, 1, 1, 1, 1, 1, 1, 1, 1, 1, 1, 1, 1, 1,
  1, 1, 1, 1, 1, 1, 1, 1, 1, 1, 1, 1, 1, 1, 1, 1, 1, 1, 1, 1, 1, 1, 1, 1, 1, 1,
  1, 1, 1, 1, 1, 1, 1, 1, 1, 1, 1, 1, 1, 1, 1, 1, 1, 1, 1, 1, 1, 1, 1, 1, 1, 1,
  1, 1, 1, 1, 1, 1, 1, 1, 1, 1, 1, 1, 1, 1, 1, 1, 1, 1, 1, 1, 1, 1, 1, 1, 1, 1,
  1, 1, 1, 1, 1, 1, 1, 1, 1, 1, 1, 1, 1, 1, 1, 1, 1, 1, 1, 1, 1, 1, 1, 1, 1, 1,
  1, 1, 1, 1, 1, 1, 1, 1, 1, 1, 1, 1, 1, 1, 1, 1, 1, 1, 1, 1, 1, 1, 1, 1, 1, 1,
  1, 1, 1, 1, 1, 1, 1, 1, 1, 1, 1, 1, 1, 1, 1, 1, 1, 1, 1, 1, 1, 1, 1, 1, 1, 1,
  1, 1, 1, 1, 1, 1, 1, 1, 1, 1, 1, 1, 1, 1, 1, 1, 1, 1, 1, 1, 1, 1, 1, 1, 1, 1,
  1, 1, 1, 1, 1, 1, 1, 1, 1, 1, 1, 1, 1, 1, 1, 1, 1, 1, 1, 1, 1, 1, 1, 1, 1, 1,
  1, 1, 1, 1, 1, 1, 1, 1, 1, 1, 1, 1, 1, 1, 1, 1, 1, 1, 1, 1, 1, 1, 1, 1, 1, 1,
  1, 1, 1, 1, 1, 1, 1, 1, 1, 1, 1, 1, 1, 1, 1, 1, 1, 1, 1, 1, 1, 1, 1, 1, 1, 1,
  1, 1, 1, 1, 1, 1, 1, 1, 1, 1, 1, 1, 1, 1, 1, 1, 1, 1, 1, 1, 1, 1, 1,
  1.004199514, 1.011355317, 1.020376047, 1.030306833, 1.040315405, 1.049679551,
  1.057775787, 1.064069127, 1.068103847, 1.069495144, 1.067921614, 1.063118461,
  1.05487137, 1.043010982, 1.027407915, 1.007968269, 0.984629584, 0.957357191,
  0.926140933, 0.890992207, 0.851941309, 0.809035042, 0.762334568, 0.711913477,
  0.657856057, 0.600255738, 0.539213701, 0.474837629, 0.407240591, 0.336540045,
  0.262856948, 0.186314961, 0.107039742, 0.025158318, -0.059201473,
  -0.145911474, -0.23484365, -0.325870466, -0.418865232, -0.513702392,
  -0.610257789, -0.708408883, -0.808034949, -0.909017242, -1.011239141,
  -1.114586263, -1.21894657, -1.324210448, -1.430270772, -1.537022962,
  -1.644365021, -1.752197565, -1.860423837, -1.968949725, -2.077683756,
  -2.186537097, -2.295423537, -2.40425948, -2.512963914, -2.621458392,
  -2.729667002, -2.83751633, -2.944935434, -3.051855797, -3.158211296,
  -3.263938159, -3.368974918, -3.473262375, -3.576743549, -3.679363638,
  -3.781069969, -3.881811958, -3.981541058, -4.080210717, -4.177776333,
  -4.274195205, -4.369426491, -4.463431162, -4.556171956, -4.647613336,
  -4.737721443, -4.826464059, -4.913810556, -4.99973186, -5.08420041,
  -5.167190111, -5.248676303, -5.328635711, -5.407046418, -5.483887817,
  -5.55914058, -5.632786617, -5.704809045, -5.775192149, -5.843921348,
  -5.910983165, -5.976365188, -6.040056044, -6.102045362, -6.162323747,
  -6.220882746, -6.27771482, -6.332813315, -6.386172436, -6.437787215,
  -6.487653488, -6.535767868, -6.582127718, -6.626731128, -6.669576888,
  -6.710664467, -6.749993988, -6.787566207, -6.82338249, -6.857444789,
  -6.889755626, -6.920318071, -6.949135717, -6.976212668, -7.001553516,
  -7.025163324, -7.047047606, -7.067212311, -7.085663808, -7.102408866,
  -7.117454639, -7.130808649, -7.142478776, -7.152473237, -7.160800572,
  -7.167469637, -7.172489579, -7.175869833, -7.177620104, -7.177750353,
  -7.17627079, -7.173191857, -7.168524218, -7.16227875, -7.154466529,
  -7.145098821, -7.134187071, -7.121742894, -7.107778063, -7.092304503,
  -7.075334278, -7.056879586, -7.036952747, -7.015566196, -6.992732476,
  -6.968464227, -6.942774183, -6.915675159, -6.887180048, -6.857301814,
  -6.826053483, -6.793448136, -6.759498907, -6.724218971, -6.687621544,
  -6.649719872, -6.610527229, -6.570056911, -6.528322228, -6.485336503,
  -6.441113065, -6.395665245, -6.34900637, -6.301149761, -6.252108726,
  -6.201896559, -6.150526535, -6.098011903, -6.044365888, -5.989601682,
  -5.933732446, -5.876771301, -5.81873133, -5.759625573, -5.699467021,
  -5.638268619, -5.57604326, -5.512803781, -5.448562964, -5.383333531,
  -5.317128145, -5.249959403, -5.181839838, -5.112781915, -5.042798029,
  -4.971900506, -4.900101596, -4.827413478, -4.753848252, -4.679417942,
  -4.604134493, -4.52800977, -4.451055555, -4.373283549, -4.294705369,
  -4.215332545, -4.135176523, -4.054248664, -3.972560236, -3.890122424,
  -3.806946321, -3.723042929, -3.638423162, -3.553097842, -3.467077697,
  -3.380373366, -3.292995393, -3.20495423, -3.116260235, -3.026923671,
  -2.936954709, -2.846363425, -2.755159799, -2.663353717, -2.570954971,
  -2.477973257, -2.384418177, -2.290299236, -2.195625846, -2.100407322,
  -2.004652885, -1.908371662, -1.811572683, -1.714264885, -1.616457109,
  -1.518158104, -1.419376523, -1.320120926, -1.220399778, -1.120221452,
  -1.019594227, -0.918526292, -0.817025739, -0.715100572, -0.612758701,
  -0.510007947, -0.406856036, -0.303310609, -0.199379213, -0.095069307,
  0.00961174, 0.114656647, 0.220058221, 0.325809356, 0.431903034, 0.538332325,
  0.645090382, 0.752170446, 0.859565842, 0.967269979, 1.07527635, 1.183578533,
  1.292170186, 1.401045051, 1.510196951, 1.619619789, 1.729307551, 1.839254299,
  1.949454176, 2.059901405, 2.170590283, 2.281515189, 2.392670573, 2.504050967,
  2.615650972, 2.72746527, 2.839488612, 2.951715825, 3.064141807, 3.17676153,
  3.289570036, 3.402562439, 3.515733922, 3.629079737, 3.742595207, 3.856275721,
  3.970116736, 4.084113777, 4.198262433, 4.312558362, 4.426997284, 4.541574983,
  4.656287309, 4.771130174, 4.88609955, 5.001191475, 5.116402044, 5.231727414,
  5.347163804, 5.462707487, 5.578354799, 5.694102133, 5.809945937, 5.925882717,
  6.041909036, 6.158021511, 6.274216815, 6.390491672, 6.506842864, 6.623267223,
  6.739761634, 6.856323033, 6.972948408, 7.089634798, 7.20637929, 7.323179023,
  7.440031181, 7.556933001, 7.673881763, 7.790874796, 7.907909476, 8.024983225,
  8.142093508, 8.259237838, 8.37641377, 8.493618902, 8.610850878, 8.728107382,
  8.845386141, 8.962684923, 9.080001539, 9.197333837, 9.314679709, 9.432037082,
  9.549403926, 9.666778246, 9.784158087, 9.901541531, 10.0189267, 10.13631174,
  10.25369485, 10.37107426, 10.48844822, 10.60581503, 10.72317303, 10.84052058,
  10.95785606, 11.07517792, 11.19248462, 11.30977464, 11.42704651, 11.54429879,
  11.66153007, 11.77873896, 11.89592411, 12.01308418, 12.13021789, 12.24732398,
  12.36440118, 12.48144831, 12.59846416, 12.71544759, 12.83239746, 12.94931266,
  13.06619211, 13.18303477, 13.29983959, 13.41660558, 13.53333174, 13.65001713,
  13.76666081, 13.88326187, 13.99981941, 14.11633258, 14.23280052, 14.34922242,
  14.46559747, 14.58192489, 14.69820393, 14.81443384, 14.93061391, 15.04674344,
  15.16282174, 15.27884815, 15.39482203, 15.51074277, 15.62660975, 15.74242238,
  15.8581801, 15.97388236, 16.08952861, 16.20511834, 16.32065105, 16.43612625,
  16.55154347, 16.66690226, 16.78220217, 16.89744279, 17.01262371, 17.12774453,
  17.24280487, 17.35780437, 17.47274268, 17.58761945, 17.70243437, 17.81718712,
  17.93187741, 18.04650494, 18.16106946, 18.27557068, 18.39000838, 18.50438231,
  18.61869225, 18.73293798, 18.8471193, 18.96123602, 19.07528797, 19.18927496,
  19.30319685, 19.41705348, 19.53084472, 19.64457044, 19.75823052, 19.87182484,
  19.98535331, 20.09881584, 20.21221235, 20.32554276, 20.43880701, 20.55200504,
  20.6651368, 20.77820226, 20.89120139, 21.00413415, 21.11700054, 21.22980055,
  21.34253417, 21.45520141, 21.56780229, 21.68033682, 21.79280503, 21.90520696,
  22.01754264, 22.12981212, 22.24201546, 22.35415271, 22.46622394, 22.57822922,
  22.69016862, 22.80204223, 22.91385013, 23.02559242, 23.13726919, 23.24888055,
  23.3604266, 23.47190747, 23.58332326, 23.6946741, 23.80596011, 23.91718143,
  24.02833819, 24.13943054, 24.25045861, 24.36142255, 24.47232252, 24.58315867,
  24.69393116, 24.80464016, 24.91528582, 25.02586833, 25.13638785, 25.24684457,
  25.35723865, 25.46757029, 25.57783966, 25.68804697, 25.7981924, 25.90827614,
  26.01829839, 26.12825936, 26.23815924, 26.34799824, 26.45777657, 26.56749444,
  26.67715205, 26.78674962, 26.89628738, 27.00576553, 27.1151843, 27.22454391,
  27.33384457, 27.44308653, 27.55227001, 27.66139523, 27.77046242, 27.87947182,
  27.98842366, 28.09731818, 28.20615562, 28.3149362, 28.42366017, 28.53232777,
  28.64093925, 28.74949483, 28.85799478, 28.96643932, 29.07482872, 29.1831632,
  29.29144303, 29.39966845, 29.5078397, 29.61595704, 29.72402072, 29.83203099,
  29.9399881, 30.04369278, 30.14438853, 30.24316694, 30.34098314, 30.43866967,
  30.53694898, 30.63644482, 30.73769242, 30.84114777, 30.94719591, 31.05615852,
  31.16830065, 31.28383686, 31.40293677, 31.52573002, 31.65231076, 31.78274171,
  31.9170578, 32.05526943, 32.19736547, 32.34331588, 32.49307411, 32.64657926,
  32.80375798, 32.96452625, 33.12879089, 33.29645098, 33.46739908, 33.64152238,
  33.81870369, 33.99882228, 34.18175476, 34.36737573, 34.5555584, 34.74617519,
  34.93909819, 35.13419963, 35.33135221, 35.5304295, 35.73130619, 35.93385837,
  36.13796375, 36.34350185, 36.55035418, 36.75840437, 36.96753826, 37.17764408,
  37.38861245, 37.60033649, 37.81271187, 38.02563683, 38.23901223, 38.45274156,
  38.66673094, 38.88088913, 39.09512756, 39.30936023, 39.52350381, 39.7374775,
  39.9512031, 40.16460494, 40.37760983, 40.59014706, 40.80214836, 41.01354782,
  41.22428192, 41.43428941, 41.64351132, 41.85189091, 42.05937359, 42.26590692,
  42.47144055, 42.67592615, 42.87931739, 43.0815699, 43.2826412, 43.48249066,
  43.68107948, 43.87837062, 44.07432876, 44.26892025, 44.4621131, 44.65387689,
  44.84418277, 45.03300339, 45.22031286, 45.40608674, 45.59030197, 45.77293683,
  45.95397094, 46.13338516, 46.31116163, 46.48728365, 46.66173573, 46.83450349,
  47.00557364, 47.17493399, 47.34257336, 47.50848158, 47.67264946, 47.83506873,
  47.99573207, 48.154633, 48.31176594, 48.46712611, 48.62070953, 48.77251302,
  48.92253413, 49.07077114, 49.21722302, 49.36188945, 49.50477072, 49.64586777,
  49.78518217, 49.92271605, 50.05847211, 50.19245361, 50.32466431, 50.45510851,
  50.58379098, 50.71071696, 50.83589214, 50.95932264, 51.08101501, 51.2009762,
  51.31921353, 51.43573469, 51.55054775, 51.66366108, 51.77508339, 51.88482372,
  51.99289137, 52.09929595, 52.20404733, 52.30715563, 52.40863123, 52.50848474,
  52.60672697, 52.70336897, 52.79842197, 52.89189741, 52.98380687, 53.07416214,
  53.16297514, 53.25025796, 53.33602281, 53.42028204, 53.50304813, 53.58433367,
  53.66415134, 53.74251393, 53.81943434, 53.89492552, 53.96900051, 54.04167243,
  54.11295443, 54.18285976, 54.25140169, 54.31859352, 54.38444864, 54.4489804,
  54.51220225, 54.57412759, 54.6347699, 54.69414262, 54.75225922, 54.80913317,
  54.86477792, 54.91920694, 54.97243367, 55.02447152, 55.07533391, 55.12503422,
  55.1735858, 55.22100196, 55.26729601, 55.31248119, 55.3565707, 55.39957771,
  55.44151534, 55.48239665, 55.52223467, 55.56104234, 55.59883258, 55.63561823,
  55.67141206, 55.7062268, 55.74007509, 55.77296952, 55.8049226, 55.83594676,
  55.86605438, 55.89525775, 55.92356908, 55.95100051, 55.9775641, 56.00327181,
  56.02813554, 56.05216711, 56.07537823, 56.09778056, 56.11938563, 56.14020492,
  56.1602498, 56.17953156, 56.19806139, 56.21585042, 56.23290964, 56.24924998,
  56.26488229, 56.27981728, 56.29406561, 56.30763783, 56.3205444, 56.33279567,
  56.34440192, 56.35537331, 56.36571993, 56.37545175, 56.38457867, 56.39311046,
  56.40105683, 56.40842737, 56.41523159, 56.4214789, 56.4271786, 56.43233991,
  56.43697195, 56.44108375, 56.44468423, 56.44778224, 56.45038651, 56.45250569,
  56.45414833, 56.45532288, 56.45603772, 56.4563011, 56.45612122, 56.45550614,
  56.45446388, 56.45300232, 56.45112928, 56.44885248, 56.44617954, 56.44311799,
  56.4396753, 56.43585882, 56.43167581, 56.42713346, 56.42223886, 56.41699903,
  56.41142088, 56.40551124, 56.39927687, 56.39272442, 56.38586048, 56.37869155,
  56.37122403, 56.36346426, 56.35541848, 56.34709286, 56.33849349, 56.32962637,
  56.32049742, 56.3111125, 56.30147737, 56.29159772, 56.28147917, 56.27112725,
  56.26054742, 56.24974508, 56.23872553, 56.22749401, 56.21605568, 56.20441564,
  56.19257891, 56.18055044, 56.1683351, 56.15593771, 56.143363, 56.13061564,
  56.11770024, 56.10462134, 56.09138339, 56.0779908, 56.06444792, 56.05075901,
  56.03692828, 56.02295988, 56.00885788, 55.99462632, 55.98026915, 55.96579026,
  55.9511935, 55.93648264, 55.92166141, 55.90673345, 55.89170239, 55.87657176,
  55.86134506, 55.84602571, 55.8306171, 55.81512256, 55.79954535, 55.7838887,
  55.76815576, 55.75234965, 55.73647343, 55.72053011, 55.70452266, 55.68845398,
  55.67232694, 55.65614434, 55.63990895, 55.6236235, 55.60729064, 55.59091301,
  55.57449319, 55.55803371, 55.54153705, 55.52500567, 55.50844196, 55.49184828,
  55.47522696, 55.45858026, 55.44191042, 55.42521963, 55.40851004, 55.39178377,
  55.37504289, 55.35828943, 55.3415254, 55.32475274, 55.30797338, 55.29118921,
  55.27440207, 55.25761378, 55.24082611, 55.22404081, 55.20725959, 55.19048411,
  55.17371602, 55.15695693, 55.14020842, 55.12347202, 55.10674925, 55.09004159,
  55.07335049, 55.05667737, 55.04002361, 55.02339059, 55.00677962, 54.99019201,
  54.97362905, 54.95709196, 54.94058198, 54.92410029, 54.90764806, 54.89122642,
  54.8748365, 54.85847938, 54.84215612, 54.82586776, 54.80961531, 54.79339976,
  54.77722208, 54.76108321, 54.74498407, 54.72892556, 54.71290855, 54.6969339,
  54.68100243, 54.66511495, 54.64927227, 54.63347513, 54.61772429, 54.60202049,
  54.58636442, 54.57075678, 54.55519824, 54.53968944, 54.52423103, 54.50882361,
  54.49346779, 54.47816414, 54.46291322, 54.44771559, 54.43257176, 54.41748225,
  54.40244756, 54.38746817, 54.37254454, 54.35767711, 54.34286633, 54.32811261,
  54.31341636, 54.29877796, 54.28419779, 54.26967622, 54.25521359, 54.24081023,
  54.22646647, 54.21218262, 54.19795897, 54.1837958, 54.1696934, 54.155652,
  54.14167187, 54.12775324, 54.11389633, 54.10010135, 54.08636851, 54.07269799,
  54.05908999, 54.04554466, 54.03206216, 54.01864266, 54.00528627, 53.99199315,
  53.9787634, 53.96559713, 53.95249446, 53.93945547, 53.92648024, 53.91356885,
  53.90072137, 53.88793786, 53.87521837, 53.86256294, 53.8499716, 53.83744438,
  53.82498131, 53.81258238, 53.80024762, 53.78797701, 53.77577055, 53.76362823,
  53.75155001, 53.73953587, 53.72758577, 53.71569968, 53.70387754, 53.69211931,
  53.68042491, 53.6687943, 53.65722739, 53.64572411, 53.63428437, 53.6229081,
  53.6115952, 53.60034556, 53.5891591, 53.5780357, 53.56697525, 53.55597764,
  53.54504275, 53.53417044, 53.52336059, 53.51261308, 53.50192775, 53.49130448,
  53.48074311, 53.47024349, 53.45980548, 53.44942892, 53.43911365, 53.4288595,
  53.41866631, 53.40853392, 53.39846214, 53.3884508, 53.37849973, 53.36860873,
  53.35877764, 53.34900626, 53.3392944, 53.32964187, 53.32004848, 53.31051402,
  53.3010383, 53.29162113, 53.28226228, 53.27296156, 53.26371876, 53.25453368,
  53.24540608, 53.23633578, 53.22732254, 53.21836615, 53.20946639, 53.20062305,
  53.19183589, 53.1831047, 53.17442924, 53.1658093, 53.15724465, 53.14873505,
  53.14028027, 53.13188009, 53.12353427, 53.11524257, 53.10700477, 53.09882062,
  53.09068988, 53.08261233, 53.07458772, 53.06661582, 53.05869637, 53.05082914,
  53.0430139, 53.03525039, 53.02753837, 53.0198776, 53.01226784, 53.00470884,
  52.99720036, 52.98974215, 52.98233396, 52.97497555, 52.96766668, 52.96040709,
  52.95319654, 52.94603479, 52.93892158, 52.93185666, 52.9248398, 52.91787074,
  52.91094923, 52.90407503, 52.89724789, 52.89046756, 52.8837338, 52.87704635,
  52.87040497, 52.86380941, 52.85725943, 52.85075477, 52.8442952, 52.83788046,
  52.83151031, 52.82518451, 52.8189028, 52.81266494, 52.80647069, 52.8003198,
  52.79421203, 52.78814713, 52.78212486, 52.77614498, 52.77020725, 52.76431142,
  52.75845725, 52.75264451, 52.74687294, 52.74114231, 52.73545239, 52.72980292,
  52.72419369, 52.71862443, 52.71309493, 52.70760494, 52.70215422, 52.69674255,
  52.69136968, 52.68603539, 52.68073944, 52.6754816, 52.67026163, 52.66507931,
  52.6599344, 52.65482668, 52.64975592, 52.64472188, 52.63972435, 52.63476309,
  52.62983789, 52.6249485, 52.62009472, 52.61527632, 52.61049307, 52.60574476,
  52.60103116, 52.59635205, 52.59170722, 52.58709644, 52.5825195, 52.57797619,
  52.57346628, 52.56898956, 52.56454583, 52.56013486, 52.55575644, 52.55141036,
  52.54709642, 52.5428144, 52.5385641, 52.5343453, 52.5301578, 52.5260014,
  52.52187589, 52.51778107, 52.51371673, 52.50968267, 52.50567869, 52.5017046,
  52.49776019, 52.49384526, 52.48995962, 52.48610307, 52.48227541, 52.47847646,
  52.47470602, 52.47096389, 52.46724988, 52.46356381, 52.45990549, 52.45627472,
  52.45267132, 52.4490951, 52.44554587, 52.44202346, 52.43852768, 52.43505834,
  52.43161527, 52.42819827, 52.42480718, 52.42144182, 52.418102, 52.41478755,
  52.41149829, 52.40823405, 52.40499465, 52.40177993, 52.3985897, 52.3954238,
  52.39228206, 52.38916431, 52.38607037, 52.38300009, 52.37995329, 52.37692981,
  52.37392949, 52.37095216, 52.36799766, 52.36506583, 52.36215651, 52.35926953,
  52.35640474, 52.35356198, 52.35074109, 52.34794192, 52.34516431, 52.34240811,
  52.33967317, 52.33695932, 52.33426643, 52.33159433, 52.32894289, 52.32631194,
  52.32370135, 52.32111097, 52.31854064, 52.31599023, 52.31345959, 52.31094858,
  52.30845705, 52.30598487, 52.30353189, 52.30109797, 52.29868297, 52.29628676,
  52.2939092, 52.29155015, 52.28920947, 52.28688704, 52.28458272, 52.28229637,
  52.28002786, 52.27777706, 52.27554385, 52.27332809, 52.27112964, 52.2689484,
  52.26678422, 52.26463698, 52.26250656, 52.26039283, 52.25829567, 52.25621495,
  52.25415056, 52.25210237, 52.25007025, 52.2480541, 52.24605379, 52.2440692,
  52.24210022, 52.24014673, 52.23820861, 52.23628575, 52.23437804, 52.23248535,
  52.23060758, 52.22874462, 52.22689636, 52.22506267, 52.22324346, 52.22143862,
  52.21964804, 52.2178716, 52.21610921, 52.21436075, 52.21262612, 52.21090522,
  52.20919795, 52.20750419, 52.20582385, 52.20415682, 52.20250301, 52.20086232,
  52.19923463, 52.19761986, 52.19601791, 52.19442868, 52.19285207, 52.19128799,
  52.18973633, 52.18819702, 52.18666994, 52.18515501, 52.18365214, 52.18216122,
  52.18068218, 52.17921492, 52.17775935, 52.17631538, 52.17488291, 52.17346188,
  52.17205217, 52.17065371, 52.16926642, 52.16789019, 52.16652496, 52.16517063,
  52.16382712, 52.16249435, 52.16117224, 52.15986069, 52.15855964, 52.157269,
  52.15598868, 52.15471861, 52.15345871, 52.1522089, 52.15096911, 52.14973925,
  52.14851924, 52.14730902, 52.1461085, 52.14491761, 52.14373627, 52.14256442,
  52.14140197, 52.14024885, 52.139105, 52.13797033, 52.13684478, 52.13572828,
  52.13462074, 52.13352212, 52.13243233, 52.1313513, 52.13027898, 52.12921528,
  52.12816014, 52.1271135, 52.12607529, 52.12504544, 52.12402388, 52.12301056,
  52.1220054, 52.12100835, 52.12001934, 52.1190383, 52.11806518, 52.1170999,
  52.11614242, 52.11519266, 52.11425058, 52.11331609, 52.11238916, 52.11146971,
  52.11055769, 52.10965305, 52.10875571, 52.10786563, 52.10698274, 52.10610699,
  52.10523833, 52.10437669, 52.10352203, 52.10267428, 52.1018334, 52.10099932,
  52.100172, 52.09935137, 52.0985374, 52.09773001, 52.09692917, 52.09613482,
  52.09534691, 52.09456539, 52.0937902, 52.09302131, 52.09225864, 52.09150217,
  52.09075183, 52.09000758, 52.08926937, 52.08853716, 52.08781088, 52.08709051,
  52.08637599, 52.08566727, 52.08496431, 52.08426706, 52.08357548, 52.08288951,
  52.08220913, 52.08153427, 52.08086491, 52.08020098, 52.07954246, 52.0788893,
  52.07824145, 52.07759887, 52.07696152, 52.07632935, 52.07570234, 52.07508043,
  52.07446358, 52.07385176, 52.07324492, 52.07264302, 52.07204603, 52.0714539,
  52.0708666, 52.07028408, 52.06970632, 52.06913326, 52.06856488, 52.06800113,
  52.06744198, 52.06688739, 52.06633732, 52.06579175, 52.06525062, 52.06471391,
  52.06418158, 52.0636536, 52.06312992, 52.06261052, 52.06209537, 52.06158442,
  52.06107764, 52.060575, 52.06007647, 52.05958202, 52.0590916, 52.05860519,
  52.05812276, 52.05764427, 52.05716969, 52.05669899, 52.05623214, 52.05576911,
  52.05530986, 52.05485437, 52.05440261, 52.05395454, 52.05351014, 52.05306938,
  52.05263222, 52.05219864, 52.05176861, 52.0513421, 52.05091908, 52.05049953,
  52.05008341, 52.04967069, 52.04926136, 52.04885538, 52.04845273, 52.04805337,
  52.04765729, 52.04726445, 52.04687483, 52.04648841, 52.04610515, 52.04572503,
  52.04534803, 52.04497412, 52.04460328, 52.04423547, 52.04387069, 52.04350889,
  52.04315006, 52.04279418, 52.04244121, 52.04209114, 52.04174394, 52.04139959,
  52.04105807, 52.04071935, 52.04038341, 52.04005023, 52.03971978, 52.03939204,
  52.039067, 52.03874462, 52.03842489, 52.03810779, 52.03779329, 52.03748137,
  52.03717202, 52.03686521, 52.03656091, 52.03625912, 52.03595981, 52.03566295,
  52.03536854, 52.03507654, 52.03478695, 52.03449973, 52.03421488, 52.03393236,
  52.03365217, 52.03337429, 52.03309868, 52.03282535, 52.03255426, 52.0322854,
  52.03201875, 52.0317543, 52.03149202, 52.03123189, 52.03097391, 52.03071805,
  52.03046429, 52.03021263, 52.02996303, 52.02971549, 52.02946998, 52.02922649,
  52.02898501, 52.02874552, 52.028508, 52.02827243, 52.0280388, 52.0278071,
  52.0275773, 52.02734939, 52.02712337, 52.0268992, 52.02667688, 52.02645639,
  52.02623771, 52.02602084, 52.02580575, 52.02559244, 52.02538088, 52.02517106,
  52.02496298, 52.02475661, 52.02455193, 52.02434895, 52.02414764, 52.02394798,
  52.02374998, 52.0235536, 52.02335884, 52.02316569, 52.02297413, 52.02278415,
  52.02259573, 52.02240887, 52.02222355, 52.02203976, 52.02185748, 52.0216767,
  52.02149742, 52.02131961, 52.02114327, 52.02096839, 52.02079495, 52.02062293,
  52.02045234, 52.02028315, 52.02011536, 52.01994896, 52.01978392, 52.01962025,
  52.01945793, 52.01929695, 52.01913729, 52.01897896, 52.01882193, 52.01866619,
  52.01851174, 52.01835857, 52.01820666, 52.018056, 52.01790659, 52.01775841,
  52.01761145, 52.01746571, 52.01732117, 52.01717782, 52.01703566, 52.01689467,
  52.01675484, 52.01661617, 52.01647864, 52.01634225, 52.01620699, 52.01607284,
  52.0159398, 52.01580786, 52.01567701, 52.01554724, 52.01541854, 52.0152909,
  52.01516432, 52.01503878, 52.01491428, 52.01479081, 52.01466836, 52.01454692,
  52.01442649, 52.01430705, 52.01418859, 52.01407112, 52.01395461, 52.01383907,
  52.01372448, 52.01361084, 52.01349814, 52.01338637, 52.01327553, 52.0131656,
  52.01305658, 52.01294846, 52.01284123, 52.01273489, 52.01262943, 52.01252484,
  52.01242111, 52.01231824, 52.01221623, 52.01211505, 52.01201471, 52.01191521,
  52.01181652, 52.01171865, 52.01162159, 52.01152533, 52.01142987, 52.0113352,
  52.01124131, 52.0111482, 52.01105585, 52.01096427, 52.01087345, 52.01078338,
  52.01069405, 52.01060547, 52.01051761, 52.01043048, 52.01034408, 52.01025838,
  52.0101734, 52.01008912, 52.01000553, 52.00992264, 52.00984043, 52.00975891,
  52.00967805, 52.00959787, 52.00951835, 52.00943949, 52.00936128, 52.00928372,
  52.0092068, 52.00913051, 52.00905486, 52.00897983, 52.00890542, 52.00883163,
  52.00875845, 52.00868588, 52.0086139, 52.00854253, 52.00847174, 52.00840154,
  52.00833191, 52.00826287, 52.0081944, 52.00812649, 52.00805914, 52.00799236,
  52.00792612, 52.00786044, 52.00779529, 52.00773069, 52.00766662, 52.00760308,
  52.00754007, 52.00747758, 52.00741561, 52.00735415, 52.0072932, 52.00723275,
  52.0071728, 52.00711335, 52.00705439, 52.00699592, 52.00693794, 52.00688043,
  52.0068234, 52.00676685, 52.00671076, 52.00665513, 52.00659997, 52.00654526,
  52.00649101, 52.0064372, 52.00638384, 52.00633092, 52.00627844, 52.0062264,
  52.00617479, 52.0061236, 52.00607284, 52.00602249, 52.00597257, 52.00592306,
  52.00587396, 52.00582526, 52.00577697, 52.00572908, 52.00568158, 52.00563448,
  52.00558777, 52.00554144, 52.0054955, 52.00544994, 52.00540476, 52.00535995,
  52.00531551, 52.00527144, 52.00522774, 52.0051844, 52.00514141, 52.00509878,
  52.00505651, 52.00501459, 52.00497301, 52.00493178, 52.00489089, 52.00485033,
  52.00481012, 52.00477024, 52.00473068, 52.00469146, 52.00465256, 52.00461398,
  52.00457572, 52.00453778, 52.00450016, 52.00446284, 52.00442584, 52.00438914,
  52.00435274, 52.00431665, 52.00428086, 52.00424536, 52.00421015, 52.00417524,
  52.00414062, 52.00410629, 52.00407223, 52.00403847, 52.00400498, 52.00397177,
  52.00393883, 52.00390617, 52.00387377, 52.00384165, 52.00380979, 52.0037782,
  52.00374687, 52.00371579, 52.00368498, 52.00365442, 52.00362411, 52.00359406,
  52.00356425, 52.00353469, 52.00350538, 52.00347631, 52.00344748, 52.00341889,
  52.00339053, 52.00336241, 52.00333453, 52.00330687, 52.00327945, 52.00325225,
  52.00322528, 52.00319853, 52.003172, 52.00314569, 52.0031196, 52.00309373,
  52.00306807, 52.00304262, 52.00301739, 52.00299236, 52.00296754, 52.00294293,
  52.00291852, 52.00289431, 52.00287031, 52.0028465, 52.00282289, 52.00279948,
  52.00277626, 52.00275323, 52.0027304, 52.00270775, 52.00268529, 52.00266302,
  52.00264093, 52.00261902, 52.0025973, 52.00257576, 52.00255439, 52.0025332,
  52.00251219, 52.00249135, 52.00247069, 52.0024502, 52.00242987, 52.00240972,
  52.00238973, 52.00236991, 52.00235025, 52.00233075, 52.00231142, 52.00229225,
  52.00227323, 52.00225438, 52.00223568, 52.00221713, 52.00219874, 52.0021805,
  52.00216241, 52.00214448, 52.00212669, 52.00210904, 52.00209155, 52.0020742,
  52.00205699, 52.00203993, 52.00202301, 52.00200623, 52.00198958, 52.00197308,
  52.00195671, 52.00194048, 52.00192438, 52.00190842, 52.00189259, 52.00187689,
  52.00186132, 52.00184588, 52.00183056, 52.00181538, 52.00180032, 52.00178538,
  52.00177057, 52.00175588, 52.00174132, 52.00172687, 52.00171255, 52.00169834,
  52.00168425, 52.00167028, 52.00165642, 52.00164268, 52.00162905, 52.00161554,
  52.00160213, 52.00158884, 52.00157566, 52.00156259, 52.00154963, 52.00153677,
  52.00152402, 52.00151138, 52.00149884, 52.0014864, 52.00147407, 52.00146184,
  52.00144972, 52.00143769, 52.00142576, 52.00141393, 52.0014022, 52.00139057,
  52.00137903, 52.00136759, 52.00135625, 52.00134499, 52.00133383, 52.00132277,
  52.00131179, 52.00130091, 52.00129012, 52.00127941, 52.0012688, 52.00125827,
  52.00124783, 52.00123748, 52.00122721, 52.00121703, 52.00120693, 52.00119692,
  52.00118699, 52.00117714, 52.00116738, 52.00115769, 52.00114809, 52.00113856,
  52.00112911, 52.00111975, 52.00111046, 52.00110124, 52.00109211, 52.00108304,
  52.00107406, 52.00106515, 52.00105631, 52.00104755, 52.00103885, 52.00103024,
  52.00102169, 52.00101321, 52.0010048, 52.00099647, 52.0009882, 52.00098,
  52.00097187, 52.00096381, 52.00095581, 52.00094788, 52.00094001, 52.00093221,
  52.00092448, 52.00091681, 52.0009092, 52.00090166, 52.00089418, 52.00088676,
  52.0008794, 52.0008721, 52.00086487, 52.00085769, 52.00085058, 52.00084352,
  52.00083652, 52.00082958, 52.0008227, 52.00081587, 52.0008091, 52.00080239,
  52.00079573, 52.00078913, 52.00078258, 52.00077609, 52.00076965, 52.00076326,
  52.00075693, 52.00075065, 52.00074442, 52.00073824, 52.00073212, 52.00072604,
  52.00072002, 52.00071404, 52.00070812, 52.00070224, 52.00069642, 52.00069064,
  52.00068491, 52.00067922, 52.00067359, 52.000668, 52.00066246, 52.00065696,
  52.00065151, 52.0006461, 52.00064074, 52.00063542, 52.00063015, 52.00062492,
  52.00061974, 52.0006146, 52.0006095, 52.00060444, 52.00059942, 52.00059445,
  52.00058952, 52.00058462, 52.00057977, 52.00057496, 52.00057019, 52.00056546,
  52.00056077, 52.00055612, 52.0005515, 52.00054693, 52.00054239, 52.00053789,
  52.00053342, 52.000529, 52.00052461, 52.00052025, 52.00051594, 52.00051166,
  52.00050741, 52.0005032, 52.00049902, 52.00049488, 52.00049078, 52.00048671,
  52.00048267, 52.00047866, 52.00047469, 52.00047075, 52.00046684, 52.00046297,
  52.00045913, 52.00045532, 52.00045154, 52.00044779, 52.00044408, 52.00044039,
  52.00043674, 52.00043312, 52.00042952, 52.00042596, 52.00042242, 52.00041892,
  52.00041544, 52.00041199, 52.00040857, 52.00040518, 52.00040182, 52.00039849,
  52.00039518, 52.0003919, 52.00038865, 52.00038543, 52.00038223, 52.00037906,
  52.00037591, 52.00037279, 52.0003697, 52.00036663, 52.00036359, 52.00036057,
  52.00035758, 52.00035461, 52.00035167, 52.00034875, 52.00034586, 52.00034299,
  52.00034014, 52.00033732, 52.00033452, 52.00033174, 52.00032899, 52.00032626,
  52.00032355, 52.00032087, 52.0003182, 52.00031556, 52.00031295, 52.00031035,
  52.00030777, 52.00030522, 52.00030269, 52.00030018, 52.00029768, 52.00029521,
  52.00029276, 52.00029033, 52.00028793, 52.00028554, 52.00028317, 52.00028082,
  52.00027849, 52.00027618, 52.00027388, 52.00027161, 52.00026936, 52.00026712,
  52.00026491, 52.00026271, 52.00026053, 52.00025837, 52.00025622, 52.0002541,
  52.00025199, 52.0002499, 52.00024782, 52.00024577, 52.00024373, 52.0002417,
  52.0002397, 52.00023771, 52.00023574, 52.00023378, 52.00023184, 52.00022992,
  52.00022801, 52.00022612, 52.00022424, 52.00022238, 52.00022053, 52.0002187,
  52.00021689, 52.00021509, 52.0002133, 52.00021153, 52.00020978, 52.00020804,
  52.00020631, 52.0002046, 52.0002029, 52.00020122, 52.00019955, 52.00019789,
  52.00019625, 52.00019462, 52.00019301, 52.0001914, 52.00018982, 52.00018824,
  52.00018668, 52.00018513, 52.00018359, 52.00018207, 52.00018056, 52.00017906,
  52.00017757, 52.0001761, 52.00017464, 52.00017319, 52.00017175, 52.00017033,
  52.00016891, 52.00016751, 52.00016612, 52.00016474, 52.00016338, 52.00016202,
  52.00016068, 52.00015934, 52.00015802, 52.00015671, 52.00015541, 52.00015412,
  52.00015284, 52.00015157, 52.00015031, 52.00014907, 52.00014783, 52.0001466,
  52.00014539, 52.00014418, 52.00014298, 52.0001418, 52.00014062, 52.00013945,
  52.0001383, 52.00013715, 52.00013601, 52.00013488, 52.00013376, 52.00013265,
  52.00013155, 52.00013046, 52.00012938, 52.0001283, 52.00012724, 52.00012618,
  52.00012514, 52.0001241, 52.00012307, 52.00012205, 52.00012103, 52.00012003,
  52.00011903, 52.00011805, 52.00011707, 52.00011609, 52.00011513, 52.00011418,
  52.00011323, 52.00011229, 52.00011136, 52.00011043, 52.00010952, 52.00010861,
  52.00010771, 52.00010681, 52.00010593, 52.00010505, 52.00010418, 52.00010331,
  52.00010245, 52.0001016, 52.00010076, 52.00009992, 52.00009909, 52.00009827,
  52.00009746, 52.00009665, 52.00009585, 52.00009505, 52.00009426, 52.00009348,
  52.0000927, 52.00009193, 52.00009117, 52.00009042, 52.00008966, 52.00008892,
  52.00008818, 52.00008745, 52.00008673, 52.00008601, 52.00008529, 52.00008458,
  52.00008388, 52.00008319, 52.0000825, 52.00008181, 52.00008113, 52.00008046,
  52.00007979, 52.00007913, 52.00007847, 52.00007782, 52.00007718, 52.00007653,
  52.0000759, 52.00007527, 52.00007465, 52.00007403, 52.00007341, 52.0000728,
  52.0000722, 52.0000716, 52.000071, 52.00007042, 52.00006983, 52.00006925,
  52.00006868, 52.00006811, 52.00006754, 52.00006698, 52.00006643, 52.00006587,
  52.00006533, 52.00006479, 52.00006425, 52.00006371, 52.00006319, 52.00006266,
  52.00006214, 52.00006163, 52.00006111, 52.00006061, 52.0000601, 52.00005961,
  52.00005911, 52.00005862, 52.00005813, 52.00005765, 52.00005717, 52.0000567,
  52.00005623, 52.00005576, 52.0000553, 52.00005484, 52.00005438, 52.00005393,
  52.00005349, 52.00005304, 52.0000526, 52.00005217, 52.00005173, 52.0000513,
  52.00005088, 52.00005046, 52.00005004, 52.00004962, 52.00004921, 52.0000488,
  52.0000484, 52.00004799, 52.0000476, 52.0000472, 52.00004681, 52.00004642,
  52.00004604, 52.00004565, 52.00004527, 52.0000449, 52.00004453, 52.00004416,
  52.00004379, 52.00004343, 52.00004307, 52.00004271, 52.00004236, 52.000042,
  52.00004165, 52.00004131, 52.00004097, 52.00004063, 52.00004029, 52.00003996,
  52.00003962, 52.00003929, 52.00003897, 52.00003865, 52.00003832, 52.00003801,
  52.00003769, 52.00003738, 52.00003707, 52.00003676, 52.00003646, 52.00003615,
  52.00003585, 52.00003556, 52.00003526, 52.00003497, 52.00003468, 52.00003439,
  52.0000341, 52.00003382, 52.00003354, 52.00003326, 52.00003299, 52.00003271,
  52.00003244, 52.00003217, 52.0000319, 52.00003164, 52.00003138, 52.00003112,
  52.00003086, 52.0000306, 52.00003035, 52.0000301, 52.00002985, 52.0000296,
  52.00002935, 52.00002911, 52.00002887, 52.00002863, 52.00002839, 52.00002816,
  52.00002792, 52.00002769, 52.00002746, 52.00002723, 52.00002701, 52.00002678,
  52.00002656, 52.00002634, 52.00002612, 52.0000259, 52.00002569, 52.00002548,
  52.00002527, 52.00002506, 52.00002485, 52.00002464, 52.00002444, 52.00002423,
  52.00002403, 52.00002383, 52.00002364, 52.00002344, 52.00002325, 52.00002305,
  52.00002286, 52.00002267, 52.00002248, 52.0000223, 52.00002211, 52.00002193,
  52.00002175, 52.00002157, 52.00002139, 52.00002121, 52.00002103, 52.00002086,
  52.00002069, 52.00002051, 52.00002034, 52.00002017, 52.00002001, 52.00001984,
  52.00001968, 52.00001951, 52.00001935, 52.00001919, 52.00001903, 52.00001887,
  52.00001872, 52.00001856, 52.00001841, 52.00001825, 52.0000181, 52.00001795,
  52.0000178, 52.00001766, 52.00001751, 52.00001736, 52.00001722, 52.00001708,
  52.00001694, 52.0000168, 52.00001666, 52.00001652, 52.00001638, 52.00001624,
  52.00001611, 52.00001598, 52.00001584, 52.00001571, 52.00001558, 52.00001545,
  52.00001532, 52.0000152, 52.00001507, 52.00001495, 52.00001482, 52.0000147,
  52.00001458, 52.00001446, 52.00001434, 52.00001422, 52.0000141, 52.00001398,
  52.00001387, 52.00001375, 52.00001364, 52.00001352, 52.00001341, 52.0000133,
  52.00001319, 52.00001308, 52.00001297, 52.00001286, 52.00001276, 52.00001265,
  52.00001255, 52.00001244, 52.00001234, 52.00001224, 52.00001213, 52.00001203,
  52.00001193, 52.00001184, 52.00001174, 52.00001164, 52.00001154, 52.00001145,
  52.00001135, 52.00001126, 52.00001116, 52.00001107, 52.00001098, 52.00001089,
  52.0000108, 52.00001071, 52.00001062, 52.00001053, 52.00001044, 52.00001036,
  52.00001027, 52.00001019, 52.0000101, 52.00001002, 52.00000994, 52.00000985,
  52.00000977, 52.00000969, 52.00000961, 52.00000953, 52.00000945, 52.00000937,
  52.00000929, 52.00000922, 52.00000914, 52.00000907, 52.00000899, 52.00000892,
  52.00000884, 52.00000877, 52.0000087, 52.00000862, 52.00000855, 52.00000848,
  52.00000841, 52.00000834, 52.00000827, 52.0000082, 52.00000813, 52.00000807,
  52.000008, 52.00000793, 52.00000787, 52.0000078, 52.00000774, 52.00000767,
  52.00000761, 52.00000755, 52.00000748, 52.00000742, 52.00000736, 52.0000073,
  52.00000724, 52.00000718, 52.00000712, 52.00000706, 52.000007, 52.00000694,
  52.00000689, 52.00000683, 52.00000677, 52.00000672, 52.00000666, 52.0000066,
  52.00000655, 52.0000065, 52.00000644, 52.00000639, 52.00000634, 52.00000628,
  52.00000623, 52.00000618, 52.00000613, 52.00000608, 52.00000603, 52.00000598,
  52.00000593, 52.00000588, 52.00000583, 52.00000578, 52.00000573, 52.00000568,
  52.00000564, 52.00000559, 52.00000554, 52.0000055, 52.00000545, 52.00000541,
  52.00000536, 52.00000532, 52.00000527, 52.00000523, 52.00000519, 52.00000514,
  52.0000051, 52.00000506, 52.00000502, 52.00000498, 52.00000493, 52.00000489,
  52.00000485, 52.00000481, 52.00000477, 52.00000473, 52.00000469, 52.00000465,
  52.00000462, 52.00000458, 52.00000454, 52.0000045, 52.00000446, 52.00000443,
  52.00000439, 52.00000435, 52.00000432, 52.00000428, 52.00000425, 52.00000421,
  52.00000418, 52.00000414, 52.00000411, 52.00000407, 52.00000404, 52.00000401,
  52.00000397, 52.00000394, 52.00000391, 52.00000387, 52.00000384, 52.00000381,
  52.00000378, 52.00000375, 52.00000372, 52.00000369, 52.00000366, 52.00000362,
  52.00000359, 52.00000356, 52.00000354, 52.00000351, 52.00000348, 52.00000345,
  52.00000342, 52.00000339, 52.00000336, 52.00000333, 52.00000331, 52.00000328,
  52.00000325, 52.00000323, 52.0000032, 52.00000317, 52.00000315, 52.00000312,
  52.00000309, 52.00000307, 52.00000304, 52.00000302, 52.00000299, 52.00000297,
  52.00000294, 52.00000292, 52.00000289, 52.00000287, 52.00000285, 52.00000282,
  52.0000028, 52.00000278, 52.00000275, 52.00000273, 52.00000271, 52.00000269,
  52.00000266, 52.00000264, 52.00000262, 52.0000026, 52.00000258, 52.00000255,
  52.00000253, 52.00000251, 52.00000249, 52.00000247, 52.00000245, 52.00000243,
  52.00000241, 52.00000239, 52.00000237, 52.00000235, 52.00000233, 52.00000231,
  52.00000229, 52.00000227, 52.00000225, 52.00000224, 52.00000222, 52.0000022,
  52.00000218, 52.00000216, 52.00000214, 52.00000213, 52.00000211, 52.00000209,
  52.00000207, 52.00000206, 52.00000204, 52.00000202, 52.00000201, 52.00000199,
  52.00000197, 52.00000196, 52.00000194, 52.00000192, 52.00000191, 52.00000189,
  52.00000188, 52.00000186, 52.00000185, 52.00000183, 52.00000182, 52.0000018,
  52.00000179, 52.00000177, 52.00000176, 52.00000174, 52.00000173, 52.00000171,
  52.0000017, 52.00000168, 52.00000167, 52.00000166, 52.00000164, 52.00000163,
  52.00000162, 52.0000016, 52.00000159, 52.00000158, 52.00000156, 52.00000155,
  52.00000154, 52.00000152, 52.00000151, 52.0000015, 52.00000149, 52.00000147,
  52.00000146, 52.00000145, 52.00000144, 52.00000143, 52.00000141, 52.0000014,
  52.00000139, 52.00000138, 52.00000137, 52.00000136, 52.00000134, 52.00000133,
  52.00000132, 52.00000131, 52.0000013, 52.00000129, 52.00000128, 52.00000127,
  52.00000126, 52.00000125, 52.00000124, 52.00000123, 52.00000122, 52.00000121,
  52.0000012, 52.00000119, 52.00000118, 52.00000117, 52.00000116, 52.00000115,
  52.00000114, 52.00000113, 52.00000112, 52.00000111, 52.0000011, 52.00000109,
  52.00000108, 52.00000107, 52.00000106, 52.00000106, 52.00000105, 52.00000104,
  52.00000103, 52.00000102, 52.00000101, 52.000001, 52.000001, 52.00000099,
  52.00000098, 52.00000097, 52.00000096, 52.00000096, 52.00000095, 52.00000094,
  52.00000093, 52.00000092, 52.00000092, 52.00000091, 52.0000009, 52.00000089,
  52.00000089, 52.00000088, 52.00000087, 52.00000086, 52.00000086, 52.00000085,
  52.00000084, 52.00000084, 52.00000083, 52.00000082, 52.00000082, 52.00000081,
  52.0000008, 52.0000008, 52.00000079, 52.00000078, 52.00000078, 52.00000077,
  52.00000076, 52.00000076, 52.00000075, 52.00000074, 52.00000074, 52.00000073,
  52.00000073, 52.00000072, 52.00000071, 52.00000071, 52.0000007, 52.0000007,
  52.00000069, 52.00000068, 52.00000068, 52.00000067, 52.00000067, 52.00000066,
  52.00000066, 52.00000065, 52.00000065, 52.00000064, 52.00000064, 52.00000063,
  52.00000062, 52.00000062, 52.00000061, 52.00000061, 52.0000006, 52.0000006,
  52.00000059, 52.00000059, 52.00000058, 52.00000058, 52.00000057, 52.00000057,
  52.00000057, 52.00000056, 52.00000056, 52.00000055, 52.00000055, 52.00000054,
  52.00000054, 52.00000053, 52.00000053, 52.00000052, 52.00000052, 52.00000052,
  52.00000051, 52.00000051, 52.0000005, 52.0000005, 52.00000049, 52.00000049,
  52.00000049, 52.00000048, 52.00000048, 52.00000047, 52.00000047, 52.00000047,
  52.00000046, 52.00000046, 52.00000046, 52.00000045, 52.00000045, 52.00000044,
  52.00000044, 52.00000044, 52.00000043, 52.00000043, 52.00000043, 52.00000042,
  52.00000042, 52.00000042, 52.00000041, 52.00000041, 52.00000041, 52.0000004,
  52.0000004, 52.0000004, 52.00000039, 52.00000039, 52.00000039, 52.00000038,
  52.00000038, 52.00000038, 52.00000037, 52.00000037, 52.00000037, 52.00000036,
  52.00000036, 52.00000036, 52.00000035, 52.00000035, 52.00000035, 52.00000035,
  52.00000034, 52.00000034, 52.00000034, 52.00000033, 52.00000033, 52.00000033,
  52.00000033, 52.00000032, 52.00000032, 52.00000032, 52.00000032, 52.00000031,
  52.00000031, 52.00000031, 52.00000031, 52.0000003, 52.0000003, 52.0000003,
  52.0000003, 52.00000029, 52.00000029, 52.00000029, 52.00000029, 52.00000028,
  52.00000028, 52.00000028, 52.00000028, 52.00000027, 52.00000027, 52.00000027,
  52.00000027, 52.00000026, 52.00000026, 52.00000026, 52.00000026, 52.00000026,
  52.00000025, 52.00000025, 52.00000025, 52.00000025, 52.00000025, 52.00000024,
  52.00000024, 52.00000024, 52.00000024, 52.00000024, 52.00000023, 52.00000023,
  52.00000023, 52.00000023, 52.00000023, 52.00000022, 52.00000022, 52.00000022,
  52.00000022, 52.00000022, 52.00000022, 52.00000021, 52.00000021, 52.00000021,
  52.00000021, 52.00000021, 52.0000002, 52.0000002, 52.0000002, 52.0000002,
  52.0000002, 52.0000002, 52.00000019, 52.00000019, 52.00000019, 52.00000019,
  52.00000019, 52.00000019, 52.00000019, 52.00000018, 52.00000018, 52.00000018,
  52.00000018, 52.00000018, 52.00000018, 52.00000017, 52.00000017, 52.00000017,
  52.00000017, 52.00000017, 52.00000017, 52.00000017, 52.00000016, 52.00000016,
  52.00000016, 52.00000016, 52.00000016, 52.00000016, 52.00000016, 52.00000016,
  52.00000015, 52.00000015, 52.00000015, 52.00000015, 52.00000015, 52.00000015,
  52.00000015, 52.00000015, 52.00000014, 52.00000014, 52.00000014, 52.00000014,
  52.00000014, 52.00000014, 52.00000014, 52.00000014, 52.00000013, 52.00000013,
  52.00000013, 52.00000013, 52.00000013, 52.00000013, 52.00000013, 52.00000013,
  52.00000013, 52.00000013, 52.00000012, 52.00000012, 52.00000012, 52.00000012,
  52.00000012, 52.00000012, 52.00000012, 52.00000012, 52.00000012, 52.00000012,
  52.00000011, 52.00000011, 52.00000011, 52.00000011, 52.00000011, 52.00000011,
  52.00000011, 52.00000011, 52.00000011, 52.00000011, 52.00000011, 52.0000001,
  52.0000001, 52.0000001, 52.0000001, 52.0000001, 52.0000001, 52.0000001,
  52.0000001, 52.0000001, 52.0000001, 52.0000001, 52.0000001, 52.00000009,
  52.00000009, 52.00000009, 52.00000009, 52.00000009, 52.00000009, 52.00000009,
  52.00000009, 52.00000009, 52.00000009, 52.00000009, 52.00000009, 52.00000009,
  52.00000008, 52.00000008, 52.00000008, 52.00000008, 52.00000008, 52.00000008,
  52.00000008, 52.00000008, 52.00000008, 52.00000008, 52.00000008, 52.00000008,
  52.00000008, 52.00000008, 52.00000008, 52.00000007, 52.00000007, 52.00000007,
  52.00000007, 52.00000007, 52.00000007, 52.00000007, 52.00000007, 52.00000007,
  52.00000007, 52.00000007, 52.00000007, 52.00000007, 52.00000007, 52.00000007,
  52.00000007, 52.00000007, 52.00000006, 52.00000006, 52.00000006, 52.00000006,
  52.00000006, 52.00000006, 52.00000006, 52.00000006, 52.00000006, 52.00000006,
  52.00000006, 52.00000006, 52.00000006, 52.00000006, 52.00000006, 52.00000006,
  52.00000006, 52.00000006, 52.00000006, 52.00000006, 52.00000005, 52.00000005,
  52.00000005, 52.00000005, 52.00000005, 52.00000005, 52.00000005, 52.00000005,
  52.00000005, 52.00000005, 52.00000005, 52.00000005, 52.00000005, 52.00000005,
  52.00000005, 52.00000005, 52.00000005, 52.00000005, 52.00000005, 52.00000005,
  52.00000005, 52.00000005, 52.00000005, 52.00000005, 52.00000004, 52.00000004,
  52.00000004, 52.00000004, 52.00000004, 52.00000004, 52.00000004, 52.00000004,
  52.00000004, 52.00000004, 52.00000004, 52.00000004, 52.00000004, 52.00000004,
  52.00000004, 52.00000004, 52.00000004, 52.00000004, 52.00000004, 52.00000004,
  52.00000004, 52.00000004, 52.00000004, 52.00000004, 52.00000004, 52.00000004,
  52.00000004, 52.00000004, 52.00000004, 52.00000004, 52.00000004, 52.00000003,
  52.00000003, 52.00000003, 52.00000003, 52.00000003, 52.00000003, 52.00000003,
  52.00000003, 52.00000003, 52.00000003, 52.00000003, 52.00000003, 52.00000003,
  52.00000003, 52.00000003, 52.00000003, 52.00000003, 52.00000003, 52.00000003,
  52.00000003, 52.00000003, 52.00000003, 52.00000003, 52.00000003, 52.00000003,
  52.00000003, 52.00000003, 52.00000003, 52.00000003, 52.00000003, 52.00000003,
  52.00000003, 52.00000003, 52.00000003, 52.00000003, 52.00000003, 52.00000003,
  52.00000003, 52.00000003, 52.00000003, 52.00000002, 52.00000002, 52.00000002,
  52.00000002, 52.00000002, 52.00000002, 52.00000002, 52.00000002, 52.00000002,
  52.00000002, 52.00000002, 52.00000002, 52.00000002, 52.00000002, 52.00000002,
  52.00000002, 52.00000002, 52.00000002, 52.00000002, 52.00000002, 52.00000002,
  52.00000002, 52.00000002, 52.00000002, 52.00000002, 52.00000002, 52.00000002,
  52.00000002, 52.00000002, 52.00000002, 52.00000002, 52.00000002, 52.00000002,
  52.00000002, 52.00000002, 52.00000002, 52.00000002, 52.00000002, 52.00000002,
  52.00000002, 52.00000002, 52.00000002, 52.00000002, 52.00000002, 52.00000002,
  52.00000002, 52.00000002, 52.00000002, 52.00000002, 52.00000002, 52.00000002,
  52.00000002, 52.00000002, 52.00000002, 52.00000002, 52.00000002, 52.00000002,
  52.00000002, 52.00000002, 52.00000002, 52.00000002, 52.00000002, 52.00000001,
  52.00000001, 52.00000001, 52.00000001, 52.00000001, 52.00000001, 52.00000001,
  52.00000001, 52.00000001, 52.00000001, 52.00000001, 52.00000001, 52.00000001,
  52.00000001, 52.00000001, 52.00000001, 52.00000001, 52.00000001, 52.00000001,
  52.00000001, 52.00000001, 52.00000001, 52.00000001, 52.00000001, 52.00000001,
  52.00000001, 52.00000001, 52.00000001, 52.00000001, 52.00000001, 52.00000001,
  52.00000001, 52.00000001, 52.00000001, 52.00000001, 52.00000001, 52.00000001,
  52.00000001, 52.00000001, 52.00000001, 52.00000001, 52.00000001, 52.00000001,
  52.00000001, 52.00000001, 52.00000001, 52.00000001, 52.00000001, 52.00000001,
  52.00000001, 52.00000001, 52.00000001, 52.00000001, 52.00000001, 52.00000001,
  52.00000001, 52.00000001, 52.00000001, 52.00000001, 52.00000001, 52.00000001,
  52.00000001, 52.00000001, 52.00000001, 52.00000001, 52.00000001, 52.00000001,
  52.00000001, 52.00000001, 52.00000001, 52.00000001, 52.00000001, 52.00000001,
  52.00000001, 52.00000001, 52.00000001, 52.00000001, 52.00000001, 52.00000001,
  52.00000001, 52.00000001, 52.00000001, 52.00000001, 52.00000001, 52.00000001,
  52.00000001, 52.00000001, 52.00000001, 52.00000001, 52.00000001, 52.00000001,
  52.00000001, 52.00000001, 52.00000001, 52.00000001, 52.00000001, 52.00000001,
  52.00000001, 52.00000001, 52.00000001, 52.00000001, 52.00000001, 52.00000001,
  52.00000001, 52.00000001, 52.00000001, 52.00000001, 52.00000001, 52.00000001,
  52.00000001, 52.00000001, 52.00000001, 52.00000001, 52.00000001, 52.00000001,
  52.00000001, 52.00000001, 52.00000001, 52.00000001, 52.00000001, 52.00000001,
  52.00000001, 52.00000001, 52.00000001, 52.00000001, 52.00000001, 52.00000001,
  52.00000001, 52.00000001, 52.00000001, 52.00000001, 52.00000001, 52, 52, 52,
  52, 52, 52, 52, 52, 52, 52, 52, 52, 52, 52, 52, 52, 52, 52, 52, 52, 52, 52,
  52, 52, 52, 52, 52, 52, 52, 52, 52, 52, 52, 52, 52, 52, 52, 52, 52, 52, 52,
  52, 52, 52, 52, 52, 52, 52, 52, 52, 52, 52, 52, 52, 52, 52, 52, 52, 52, 52,
  52, 52, 52, 52, 52, 52, 52, 52, 52, 52, 52, 52, 52, 52, 52, 52, 52, 52, 52,
  52, 52, 52, 52, 52, 52, 52, 52, 52, 52, 52, 52, 52, 52, 52, 52, 52, 52, 52,
  52, 52, 52, 52, 52, 52, 52, 52, 52, 52, 52, 52, 52, 52, 52, 52, 52, 52, 52,
  52, 52, 52, 52, 52, 52, 52, 52, 52, 52, 52, 52, 52, 52, 52, 52, 52, 52, 52,
  52, 52, 52, 52, 52, 52, 52, 52, 52, 52, 52, 52, 52, 52, 52, 52, 52, 52, 52,
  52, 52, 52, 52, 52, 52, 52, 52, 52, 52, 52, 52, 52, 52, 52, 52, 52, 52, 52,
  52, 52, 52, 52, 52, 52, 52, 52, 52, 52, 52, 52, 52, 52, 52, 52, 52, 52, 52,
  52, 52, 52, 52, 52, 52, 52, 52, 52, 52, 52, 52, 52, 52, 52, 52, 52, 52, 52,
  52, 52, 52, 52, 52, 52, 52, 52, 52, 52, 52, 52, 52, 52, 52, 52, 52, 52, 52,
  52, 52, 52, 52, 52, 52, 52, 52, 52, 52, 52, 52, 52, 52, 52, 52, 52, 52, 52,
  52, 52, 52, 52, 52, 52, 52, 52, 52, 52, 52, 52, 52, 52, 52, 52, 52, 52, 52,
  52, 52, 52, 52, 52, 52, 52, 52, 52, 52, 52, 52, 52, 52, 52, 52, 52, 52, 52,
  52, 52, 52, 52, 52, 52, 52, 52, 52, 52, 52, 52, 52, 52, 52, 52, 52, 52, 52,
  52, 52, 52, 52, 52, 52, 52, 52, 52, 52, 52, 52, 52, 52, 52, 52, 52, 52, 52,
  52, 52, 52, 52, 52, 52, 52, 52, 52, 52, 52, 52, 52, 52, 52, 52, 52, 52, 52,
  52, 52, 52, 52, 52, 52, 52, 52, 52, 52, 52, 52, 52, 52, 52, 52, 52, 52, 52,
  52, 52, 52, 52, 52, 52, 52, 52, 52, 52, 52, 52, 52, 52, 52, 52, 52, 52, 52,
  52, 52, 52, 52, 52, 52, 52, 52, 52, 52, 52, 52, 52, 52, 52, 52, 52, 52, 52,
  52, 52, 52, 52, 52, 52, 52, 52, 52, 52, 52, 52, 52, 52, 52, 52, 52, 52, 52,
  52, 52, 52, 52, 52, 52, 52, 52, 52, 52, 52, 52, 52, 52, 52, 52, 52, 52, 52,
  52, 52, 52, 52, 52, 52, 52, 52, 52, 52, 52, 52, 52, 52, 52, 52, 52, 52, 52,
  52, 52, 52, 52, 52, 52, 52, 52, 52, 52, 52, 52, 52, 52, 52, 52, 52, 52, 52,
  52, 52, 52, 52, 52, 52, 52, 52, 52, 52, 52, 52, 52, 52, 52, 52, 52, 52, 52,
  52, 52, 52, 52, 52, 52, 52, 52, 52, 52, 52, 52, 52, 52, 52, 52, 52, 52, 52,
  52, 52, 52, 52, 52, 52, 52, 52, 52, 52, 52, 52, 52, 52, 52, 52, 52, 52, 52,
  52, 52, 52, 52, 52, 52, 52, 52, 52, 52, 52, 52, 52, 52, 52, 52, 52, 52, 52,
  52, 52, 52, 52, 52, 52, 52, 52, 52, 52, 52, 52, 52, 52, 52, 52, 52, 52, 52,
  52, 52, 52, 52, 52, 52, 52, 52, 52, 52, 52, 52, 52, 52, 52, 52, 52, 52, 52,
  52, 52, 52, 52, 52, 52, 52, 52, 52, 52, 52, 52, 52, 52, 52, 52, 52, 52, 52,
  52, 52, 52, 52, 52, 52, 52, 52, 52, 52, 52, 52, 52, 52, 52, 52, 52, 52, 52,
  52, 52, 52, 52, 52, 52, 52, 52, 52, 52, 52, 52, 52, 52, 52, 52, 52, 52, 52,
  52, 52, 52, 52, 52, 52, 52, 52, 52, 52, 52, 52, 52, 52, 52, 52, 52, 52, 52,
  52, 52, 52, 52, 52, 52, 52, 52, 52, 52, 52, 52, 52, 52, 52, 52, 52, 52, 52,
  52, 52, 52, 52, 52, 52, 52, 52, 52, 52, 52, 52, 52, 52, 52, 52, 52, 52, 52,
  52, 52, 52, 52, 52, 52, 52, 52, 52, 52, 52, 52, 52, 52, 52, 52, 52, 52, 52,
  52, 52, 52, 52, 52, 52, 52, 52, 52, 52, 52, 52, 52, 52, 52, 52, 52, 52, 52,
  52, 52, 52, 52, 52, 52, 52, 52, 52, 52, 52, 52, 52, 52, 52, 52, 52, 52, 52,
  52, 52, 52, 52, 52, 52, 52, 52, 52, 52, 52, 52, 52, 52, 52, 52, 52, 52, 52,
  52, 52, 52, 52, 52, 52, 52, 52, 52, 52, 52, 52, 52, 52, 52, 52, 52, 52, 52,
  52, 52, 52, 52, 52, 52, 52, 52, 52, 52, 52, 52, 52, 52, 52, 52, 52, 52, 52,
  52, 52, 52, 52, 52, 52, 52, 52, 52, 52, 52, 52, 52, 52, 52, 52, 52, 52, 52,
  52, 52, 52, 52, 52, 52, 52, 52, 52, 52, 52, 52, 52, 52, 52, 52, 52, 52, 52,
  52, 52, 52, 52, 52, 52, 52, 52, 52, 52, 52, 52, 52, 52, 52, 52, 52, 52, 52,
  52, 52, 52, 52, 52, 52, 52, 52, 52, 52, 52, 52, 52, 52, 52, 52, 52, 52, 52,
  52, 52, 52, 52, 52, 52, 52, 52, 52, 52, 52, 52, 52, 52, 52, 52, 52, 52, 52,
  52, 52, 52, 52, 52, 52, 52, 52, 52, 52, 52, 52, 52, 52, 52, 52, 52, 52, 52,
  52, 52, 52, 52, 52, 52, 52, 52, 52, 52, 52, 52, 52, 52, 52, 52, 52, 52, 52,
  52, 52, 52, 52, 52, 52, 52, 52, 52, 52, 52, 52, 52, 52, 52, 52, 52, 52, 52,
  52, 52, 52, 52, 52, 52, 52, 52, 52, 52, 52, 52, 52, 52, 52, 52, 52, 52, 52,
  52, 52, 52, 52, 52, 52, 52, 52, 52, 52, 52, 52, 52, 52, 52, 52, 52, 52, 52,
  52, 52, 52, 52, 52, 52, 52, 52, 52, 52, 52, 52, 52, 52, 52, 52, 52, 52, 52,
  52, 52, 52, 52, 52, 52, 52, 52, 52, 52, 52, 52, 52, 52, 52, 52, 52, 52, 52,
  52, 52, 52, 52, 52, 52, 52, 52, 52, 52, 52, 52, 52, 52, 52, 52, 52, 52, 52,
  52, 52, 52, 52, 52, 52, 52, 52, 52, 52, 52, 52, 52, 52, 52, 52, 52, 52, 52,
  52, 52, 52, 52, 52, 52, 52, 52, 52, 52, 52, 52, 52, 52, 52, 52, 52, 52, 52,
  52, 52, 52, 52, 52, 52, 52, 52, 52, 52, 52, 52, 52, 52, 52, 52, 52, 52, 52,
  52, 52, 52, 52, 52, 52, 52, 52, 52, 52, 52, 52, 52, 52, 52, 52, 52, 52, 52,
  52, 52, 52, 52, 52, 52, 52, 52, 52, 52, 52, 52, 52, 52, 52, 52, 52, 52, 52,
  52, 52, 52, 52, 52, 52, 52, 52, 52, 52, 52, 52, 52, 52, 52, 52, 52, 52, 52,
  52, 52, 52, 52, 52, 52, 52, 52, 52, 52, 52, 52, 52, 52, 52, 52, 52, 52, 52,
  52, 52, 52, 52, 52, 52, 52, 52, 52, 52, 52, 52, 52, 52, 52, 52, 52, 52, 52,
  52, 52, 52, 52, 52, 52, 52, 52, 52, 52, 52, 52, 52, 52, 52, 52, 52, 52, 52,
  52, 52, 52, 52, 52, 52, 52, 52, 52, 52, 52, 52, 52, 52, 52, 52, 52, 52, 52,
  52, 52, 52, 52, 52, 52, 52, 52, 52, 52, 52, 52, 52, 52, 52, 52, 52, 52, 52,
  52, 52, 52, 52, 52, 52, 52, 52, 52, 52, 52, 52, 52, 52, 52, 52, 52, 52, 52,
  52, 52, 52, 52, 52, 52, 52, 52, 52, 52, 52, 52, 52, 52, 52, 52, 52, 52, 52,
  52, 52, 52, 52, 52, 52, 52, 52, 52, 52, 52, 52, 52, 52, 52, 52, 52, 52, 52,
  52, 52, 52, 52, 52, 52, 52, 52, 52, 52, 52, 52, 52, 52, 52, 52, 52, 52, 52,
  52, 52, 52, 52, 52, 52, 52, 52, 52, 52, 52, 52, 52, 52, 52, 52, 52, 52, 52,
  52, 52, 52, 52, 52, 52, 52, 52, 52, 52, 52, 52, 52, 52, 52, 52, 52, 52, 52,
  52, 52, 52, 52, 52, 52, 52, 52, 52, 52, 52, 52, 52, 52, 52, 52, 52, 52, 52,
  52, 52, 52, 52, 52, 52, 52, 52, 52, 52, 52, 52, 52, 52, 52, 52, 52, 52, 52,
  52, 52, 52, 52, 52, 52, 52, 52, 52, 52, 52, 52, 52, 52, 52, 52, 52, 52, 52,
  52, 52, 52, 52, 52, 52, 52, 52, 52, 52, 52, 52, 52, 52, 52, 52, 52, 52, 52,
  52, 52, 52, 52, 52, 52, 52, 52, 52, 52, 52, 52, 52, 52, 52, 52, 52, 52, 52,
  52, 52, 52, 52, 52, 52, 52, 52, 52, 52, 52, 52, 52, 52, 52, 52, 52, 52, 52,
  52, 52, 52, 52, 52, 52, 52, 52, 52, 52, 52, 52, 52, 52, 52, 52, 52, 52, 52,
  52, 52, 52, 52, 52, 52, 52, 52, 52, 52, 52, 52, 52, 52, 52, 52, 52, 52, 52,
  52, 52, 52, 52, 52, 52, 52, 52, 52, 52, 52, 52, 52, 52, 52, 52, 52, 52, 52,
  52, 52, 52, 52, 52, 52, 52, 52, 52, 52, 52, 52, 52, 52, 52, 52, 52, 52, 52,
  52, 52, 52, 52, 52, 52, 52, 52, 52, 52, 52, 52, 52, 52, 52, 52, 52, 52, 52,
  52, 52, 52, 52, 52, 52, 52, 52, 52, 52, 52, 52, 52, 52, 52, 52, 52, 52, 52,
  52, 52, 52, 52, 52, 52, 52, 52, 52, 52, 52, 52, 52, 52, 52, 52, 52, 52, 52,
  52, 52, 52, 52, 52, 52, 52, 52, 52, 52, 52, 52, 52, 52, 52, 52, 52, 52, 52,
  52, 52, 52, 52, 52, 52, 52, 52, 52, 52, 52, 52, 52, 52, 52, 52, 52, 52, 52,
  52, 52, 52, 52, 52, 52, 52, 52, 52, 52, 52, 52, 52, 52, 52, 52, 52, 52, 52,
  52, 52, 52, 52, 52, 52, 52, 52, 52, 52, 52, 52, 52, 52, 52, 52, 52, 52, 52,
  52, 52, 52, 52, 52, 52, 52, 52, 52, 52, 52, 52, 52, 52, 52, 52, 52, 52, 52,
  52, 52, 52, 52, 52, 52, 52, 52, 52, 52, 52, 52, 52, 52, 52, 52, 52, 52, 52,
  52, 52, 52, 52, 52, 52, 52, 52, 52, 52, 52, 52, 52, 52, 52, 52, 52, 52, 52,
  52, 52, 52, 52, 52, 52, 52, 52, 52, 52, 52, 52, 52, 52, 52, 52, 52, 52, 52,
  52, 52, 52, 52, 52, 52, 52, 52, 52, 52, 52, 52, 52, 52, 52, 52, 52, 52, 52,
  52, 52, 52, 52, 52, 52, 52, 52, 52, 52, 52, 52, 52, 52, 52, 52, 52, 52, 52,
  52, 52, 52, 52, 52, 52, 52, 52, 52, 52, 52, 52, 52, 52, 52, 52, 52, 52, 52,
  52, 52, 52, 52, 52, 52, 52, 52, 52, 52, 52, 52, 52, 52, 52, 52, 52, 52, 52,
  52, 52, 52, 52, 52, 52, 52, 52, 52, 52, 52, 52, 52, 52, 52, 52, 52, 52, 52,
  52, 52, 52, 52, 52, 52, 52, 52, 52, 52, 52, 52, 52, 52, 52, 52, 52, 52, 52,
  52, 52, 52, 52, 52, 52, 52, 52, 52, 52, 52, 52, 52, 52, 52, 52, 52, 52, 52,
  52, 52, 52, 52, 52, 52, 52, 52, 52, 52, 52, 52, 52, 52, 52, 52, 52, 52, 52,
  52, 52, 52, 52, 52, 52, 52, 52, 52, 52, 52, 52, 52, 52, 52, 52, 52, 52, 52,
  52, 52, 52, 52, 52, 52, 52, 52, 52, 52, 52, 52, 52, 52, 52, 52, 52, 52, 52,
  52, 52, 52, 52, 52, 52, 52, 52, 52, 52, 52, 52, 52, 52, 52, 52, 52, 52, 52,
  52, 52, 52, 52, 52, 52, 52, 52, 52, 52, 52, 52, 52, 52, 52, 52, 52, 52, 52,
  52, 52, 52, 52, 52, 52, 52, 52, 52, 52, 52, 52, 52, 52, 52, 52, 52, 52, 52,
  52, 52, 52, 52, 52, 52, 52, 52, 52, 52, 52, 52, 52, 52, 52, 52, 52, 52, 52,
  52, 52, 52, 52, 52, 52, 52, 52, 52, 52, 52, 52, 52, 52, 52, 52, 52, 52, 52,
  52, 52, 52, 52, 52, 52, 52, 52, 52, 52, 52, 52, 52, 52, 52, 52, 52, 52, 52,
  52, 52, 52, 52, 52, 52, 52, 52, 52, 52, 52, 52, 52, 52, 52, 52, 52, 52, 52,
  52, 52, 52, 52, 52, 52, 52, 52, 52, 52, 52, 52, 52, 52, 52, 52, 52, 52, 52,
  52, 52, 52, 52, 52, 52, 52, 52, 52, 52, 52, 52, 52, 52, 52, 52, 52, 52, 52,
  52, 52, 52, 52, 52, 52, 52, 52, 52, 52, 52, 52, 52, 52, 52, 52, 52, 52, 52,
  52, 52, 52, 52, 52, 52, 52, 52, 52, 52, 52, 52, 52, 52, 52, 52, 52, 52, 52,
  52, 52, 52, 52, 52, 52, 52, 52, 52, 52, 52, 52, 52, 52, 52, 52, 52, 52, 52,
  52, 52, 52, 52, 52, 52, 52, 52, 52, 52, 52, 52, 52, 52, 52, 52, 52, 52, 52,
  52, 52, 52, 52, 52, 52, 52, 52, 52, 52, 52, 52, 52, 52, 52, 52, 52, 52, 52,
  52, 52, 52, 52, 52, 52, 52, 52, 52, 52, 52, 52, 52, 52, 52, 52, 52, 52, 52,
  52, 52, 52, 52, 52, 52, 52, 52, 52, 52, 52, 52, 52, 52, 52, 52, 52, 52, 52,
  52, 52, 52, 52, 52, 52, 52, 52, 52, 52, 52, 52, 52, 52, 52, 52, 52, 52, 52,
  52, 52, 52, 52, 52, 52, 52, 52, 52, 52, 52, 52, 52, 52, 52, 52, 52, 52, 52,
  52, 52, 52, 52, 52, 52, 52, 52, 52, 52, 52, 52, 52, 52, 52, 52, 52, 52, 52,
  52, 52, 52, 52, 52, 52, 52, 52, 52, 52, 52, 52, 52, 52, 52, 52, 52, 52, 52,
  52, 52, 52, 52, 52, 52, 52, 52, 52, 52, 52, 52, 52, 52, 52, 52, 52, 52, 52,
  52, 52, 52, 52, 52, 52, 52, 52, 52, 52, 52, 52, 52, 52, 52, 52, 52, 52, 52,
  52, 52, 52, 52, 52, 52, 52, 52, 52, 52, 52, 52, 52, 52, 52, 52, 52, 52, 52,
  52, 52, 52, 52, 52, 52, 52, 52, 52, 52, 52, 52, 52, 52, 52, 52, 52, 52, 52,
  52, 52, 52, 52, 52, 52, 52, 52, 52, 52, 52, 52, 52, 52, 52, 52, 52, 52, 52,
  52, 52, 52, 52, 52, 52, 52, 52, 52, 52, 52, 52, 52, 52, 52, 52, 52, 52, 52,
  52, 52, 52, 52, 52, 52, 52, 52, 52, 52, 52, 52, 52, 52, 52, 52, 52, 52, 52,
  52, 52, 52, 52, 52, 52, 52, 52, 52, 52, 52, 52, 52, 52, 52, 52, 52, 52, 52,
  52, 52, 52, 52, 52, 52, 52, 52, 52, 52, 52, 52, 52, 52, 52, 52, 52, 52, 52,
  52, 52, 52, 52, 52, 52, 52, 52, 52, 52, 52, 52, 52, 52, 52, 52, 52, 52, 52,
  52, 52, 52, 52, 52, 52, 52, 52, 52, 52, 52, 52, 52, 52, 52, 52, 52, 52, 52,
  52, 52, 52, 52, 52, 52, 52, 52, 52, 52, 52, 52, 52, 52, 52, 52, 52, 52, 52,
  52, 52, 52, 52, 52, 52, 52, 52, 52, 52, 52, 52, 52, 52, 52, 52, 52, 52, 52,
  52, 52, 52, 52, 52, 52, 52, 52, 52, 52, 52, 52, 52, 52, 52, 52, 52, 52, 52,
  52, 52, 52, 52, 52, 52, 52, 52, 52, 52, 52, 52, 52, 52, 52, 52, 52, 52, 52,
  52, 52, 52, 52, 52, 52, 52, 52, 52, 52, 52, 52, 52, 52, 52, 52, 52, 52, 52,
  52, 52, 52,
];
