import classes from "./Heading.module.css";

const Heading: React.FC<{ intro: string; heading: string }> = (props: {
  intro: string;
  heading: string;
}) => {
  return (
    <div className={classes.homewrap}>
      <div className={classes.mottowrap}>
        <div className={classes.labellight}>{props.intro}</div>
        <div className={classes.heading}>{props.heading}</div>
      </div>
    </div>
  );
};

export default Heading;
