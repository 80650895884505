import { Card, Col, Row } from "react-bootstrap";
import Heading from "../components/Heading";
import PageContent from "../components/PageContent";
import TextBlock from "../components/TextBlock";
import useDocumentTitle from "../hooks/DocumentTitle";
import classes from "./About.module.css";

const About: React.FC = () => {
  useDocumentTitle("RC Solutions - About Us");
  return (
    <>
      <PageContent
        title="About Us"
        heading="And The Work We Do"
        image="../static/images/refinery.jpg"
      >
        <Heading
          intro=""
          heading="We strive to add value to the world by increasing efficiency and reliability through better automation."
        />
        <TextBlock text="In today's fast paced digital world it is not hard to build an application for a specific automation task. Many systems make it easy to automate and implement controllers or applications that take care of tasks that have previously required human labour." />
        <TextBlock text="With the advancements in technology, it has also become easier for engineers to implement redundant code or implement automation in a way that is not always the best solution to the problem, requiring more processing power and memory to achieve the goal, increasing total cost of ownership over the long run." />
        <TextBlock text="We strive to implement optimal solutions to automation problems, reduce operating cost and increase reliability and efficiency through well designed applications. Reducing plant upsets and handling them in a stable manner is not only good for business but the environment too." />
        <Heading intro="AREAS OF" heading="Expertise" />
        <Row xs={1} md={3} className="g-4" key="firstrow">
          <Col>
            <Card className={classes.cardwidth}>
              <img
                src="../static/icons/Engineer.png"
                width="50"
                alt="engineer icon"
              />
              <Card.Body>
                <Card.Title>Process Control Optimization</Card.Title>
                <Card.Text>
                  {[
                    "Whether you are looking to increase reliability or efficiency we can assist you to design with plant limitations in mind. ",
                    <br key="break1" />,
                    <br key="break2" />,
                    "If you suffer from unnecessary plant downtime and startup time, we can assist you in finding ways to reduce the number of plant trips and add value to your overall production and efficiency.",
                  ]}
                </Card.Text>
                {/* <Button variant="primary">Go somewhere</Button>*/}
              </Card.Body>
            </Card>
          </Col>
          <Col>
            <Card className={classes.cardwidth}>
              <img
                src="../static/icons/Repair.png"
                width={50}
                alt="repair icon"
              />
              <Card.Body>
                <Card.Title>Honeywell DCS Migration</Card.Title>
                <Card.Text>
                  {[
                    "Migrating from TDC? We can assist you in interpretation of your existing control applications to ensure your custom engineered solutions are captured in your new DCS.",
                    <br key="break3" />,
                    <br key="break4" />,
                    "We have expertise in Control Loop Design, configuration and development, HMI Design and start up-support and -optimization.",
                  ]}
                </Card.Text>
                {/* <Button variant="primary">Go somewhere</Button>*/}
              </Card.Body>
            </Card>
          </Col>
          <Col>
            <Card className={classes.cardwidth}>
              <img
                src="../static/icons/Planning.png"
                width="50"
                alt="planning icon"
              />
              <Card.Body>
                <Card.Title>Capacity And Distribution Planner</Card.Title>
                <Card.Text>
                  {[
                    "We can assist you in the design, development and deployment of an enterprise grade Production and Distribution planning system to your private or hybrid cloud.",
                    <br key="break5" />,
                    <br key="break6" />,
                    "Have a complete overview of all inventories, capacities and movements in your fuel distribution network at a future point in time.",
                  ]}
                </Card.Text>
                {/* <Button variant="primary">Go somewhere</Button>*/}
              </Card.Body>
            </Card>
          </Col>
        </Row>
        <Row xs={1} md={1} className={classes.rowheight} key="secondrow">
          <br />
        </Row>
        <Row xs={1} md={3} className="g-4" key="thirdrow">
          <Col>
            <Card className={classes.cardwidth}>
              <img
                src="../static/icons/Blueprint.png"
                width="50"
                alt="blueprint icon"
              />
              <Card.Body>
                <Card.Title>
                  Process Automation and Application Design
                </Card.Title>
                <Card.Text>
                  {[
                    "Involving the right people from the start can save a lot of engineering time when it comes to designing the right application for the job.",
                    <br key="break7" />,
                    <br key="break8" />,
                    "Regulatory Control of processes can be one of the most important aspects of any process interaction.",
                    <br key="break9" />,
                    <br key="break10" />,
                    "We can help you achieve stability at any operating load and have expertise in almost all areas modern process control applications.",
                    <br key="break11" />,
                    <br key="break12" />,
                    "Motor load protection or limiting, control loop linearization, adaptive gain control, feed forward, drum level, condenser level, pressure control, automatic startup - we have a solution for your need!",
                  ]}
                </Card.Text>
                {/* <Button variant="primary">Go somewhere</Button> */}
              </Card.Body>
            </Card>
          </Col>
          <Col>
            {" "}
            <Card className={classes.cardwidth}>
              <img
                src="../static/icons/Applications.png"
                width={50}
                alt="application icon"
              />
              <Card.Body>
                <Card.Title>Alarm Management and Alarm Reduction</Card.Title>
                <Card.Text>
                  {[
                    "When your plant has an upset condition, the response of your operation staff could mean a lot of money saved in production losses and startup costs.",
                    <br key="break13" />,
                    <br key="break14" />,
                    "We can assist you in the implementation of an alarm management system to ensure your operators only react on the most needed alarms during upset conditions and increase their chances of successfully keeping your process online.",
                  ]}
                </Card.Text>
                {/* <Button variant="primary">Go somewhere</Button> */}
              </Card.Body>
            </Card>
          </Col>
          <Col>
            <Card className={classes.cardwidth}>
              <img
                src="../static/icons/Tools.png"
                width="50"
                alt="tools icon"
              />
              <Card.Body>
                <Card.Title>Honeywell DCS Maintenance</Card.Title>
                <Card.Text>
                  {[
                    "We can assist you in setting up Asset Management Solutions for your specific needs.",
                    <br key="break16" />,
                    <br key="break17" />,
                    "Have a quick overview of where problems arise within your control system or where pumps, fans, valves, dampers or transmitters need maintenance.",
                    <br key="break18" />,
                    <br key="break19" />,
                    "Boost your plant performance by acting proactively on maintenance activities and draw up reports with ease.",
                  ]}
                </Card.Text>
                {/* <Button variant="primary">Go somewhere</Button>*/}
              </Card.Body>
            </Card>
          </Col>
        </Row>
      </PageContent>
    </>
  );
};

export default About;
