import { isRouteErrorResponse, useRouteError } from "react-router-dom";
import NavBar from "../components/NavBar";
import PageContent from "../components/PageContent";
//import classes from "./Error.module.css";

const ErrorPage: React.FC = () => {
  const error = useRouteError();

  let title = "An error occured";
  let message = "Something went wrong";
  if (isRouteErrorResponse(error)) {
    if (error.status === 500) {
      message = error.data.message;
    }

    if (error.status === 404) {
      title = "Not Found!";
      message = "Could not find resource or page.";
    }
  }
  return (
    <>
      <NavBar />
      <PageContent
        title={title}
        heading={message}
        image="../static/images/refinery.jpg"
      >
        <></>
      </PageContent>
    </>
  );
};

export default ErrorPage;
