import { NavLink } from "react-router-dom";
import { GiHamburgerMenu } from "react-icons/gi";
import { AiOutlineClose } from "react-icons/ai";
import { MenuData } from "./MenuData";
import classes from "./NavBar.module.css";
import { useEffect, useRef, useState } from "react";

const NavBar: React.FC = () => {
  const windowSize = useRef([window.innerWidth]);
  const [clicked, setClicked] = useState<boolean>(false);
  const handleClick = () => {
    setClicked((prevState) => !prevState);
  };
  useEffect(() => {
    if (windowSize.current[0] >= 850) {
      setClicked(false);
    }
  }, [windowSize]);

  return (
    <header className={classes.header}>
      <nav className={classes.NavBarItems}>
        <NavLink to="/" className={classes.logo} end>
          <img
            src="../static/images/logo.png"
            width="230"
            alt="company logo"
          ></img>
        </NavLink>
        <div className={classes.MenuIcon} onClick={handleClick}>
          {!clicked && <GiHamburgerMenu />}
          {clicked && <AiOutlineClose />}
        </div>
        <ul
          className={
            clicked
              ? `${classes.NavMenu} ${classes.active}`
              : `${classes.NavMenu}`
          }
        >
          {MenuData.map((item, index) => {
            return (
              <li key={index}>
                <NavLink
                  to={item.url}
                  end
                  className={({ isActive }) =>
                    isActive
                      ? `${classes.NavLinks} ${classes.active}`
                      : classes.NavLinks
                  }
                >
                  {item.title}
                </NavLink>
              </li>
            );
          })}
        </ul>
      </nav>
    </header>
  );
};

export default NavBar;
