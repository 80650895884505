import { Link } from "react-router-dom";
import Details from "../components/Details";
import Heading from "../components/Heading";
import PageContent from "../components/PageContent";
import useDocumentTitle from "../hooks/DocumentTitle";
import classes from "./Contact.module.css";

const Contact: React.FC = () => {
  useDocumentTitle("RC Solutions - Contact Us");
  return (
    <>
      <PageContent
        title="Contact Us Today"
        heading="We can connect you to the right advice"
        image="../static/images/refinery.jpg"
      >
        <Heading
          intro="WE'D&nbsp;LOVE&nbsp;TO HEAR FROM YOU"
          heading="If you have any questions or would like to get more information about our service offerings"
        />

        <div className={classes.padding}>
          <Link
            className={`${classes.button} button`}
            to={
              "mailto:info@regulatorycontrol.com?subject=Web%20Page%20Contact"
            }
          >
            Send us an email
          </Link>
        </div>
      </PageContent>
      <div className={classes.contact}>
        <Details
          label="Our Office"
          paragraph="is located in Stockholm, Sweden"
        />
        <Details label="CONTACT" paragraph="info@regulatorycontrol.com" />
      </div>
    </>
  );
};

export default Contact;
