import useDocumentTitle from "../hooks/DocumentTitle";
import classes from "./Details.module.css";

const Details: React.FC<{ label: string; paragraph: string }> = ({
  label,
  paragraph,
}) => {
  useDocumentTitle("RC Solutions - Contact Us");
  return (
    <div>
      <div className={classes.label}>{label}</div>
      <div className={classes.paragraph}>{paragraph}</div>
    </div>
  );
};

export default Details;
