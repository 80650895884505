import { Link } from "react-router-dom";
import Heading from "../components/Heading";
import HomeDescription from "../components/HomeDescription";
import PageContent from "../components/PageContent";
import useDocumentTitle from "../hooks/DocumentTitle";
import React from "react";
import Trend from "react-trend";
import classes from "./Home.module.css";
import { chartData } from "./HomeTrend";
import { Row } from "react-bootstrap";

const Home: React.FC = () => {
  useDocumentTitle("RC Solutions");
  return (
    <>
      <PageContent
        title="Applications That Increase Business Value"
        heading="Optimal performance of your assets start at the base layer"
        image="../static/images/refinery.jpg"
      >
        <Heading
          intro="ASSISTING&nbsp;CLIENTS TO&nbsp;IMPLEMENT"
          heading="Quality solutions to the most complex problems"
        ></Heading>
        <Row className={classes.content}>
          <HomeDescription
            label="ABOUT US"
            heading="Who we are"
            text1="We are passionate about process automation and optimization and believe that properly designed applications can increase the value of your assets."
            text2="Caring about the environment is an important factor behind our constant drive to improve efficiency and reliability."
            image="../static/images/controlroom.jpg"
            alttext="control room"
            leftallign={true}
          />
        </Row>
        <Row>
          <div className={classes.container}>
            <div className={classes.trendbox1}>
              <Trend
                smooth
                autoDraw
                autoDrawDuration={15000}
                autoDrawEasing="ease-out"
                data={chartData}
                gradient={["#8064a2"]}
                radius={1}
                strokeWidth={3.0}
                strokeLinecap={"round"}
              />
            </div>
            <div className={classes.trendbox2}>
              <Trend
                smooth
                autoDraw
                autoDrawDuration={20000}
                autoDrawEasing="ease-out"
                data={chartData}
                gradient={["#009900"]}
                radius={1}
                strokeWidth={3.0}
                strokeLinecap={"round"}
              />
            </div>
            <div className={`${classes.box} ${classes.overlay}`}>
              <Link className={`${classes.button} button`} to={"/About"}>
                Learn More
              </Link>
            </div>
          </div>
        </Row>
        <Row className={classes.content}>
          <HomeDescription
            label="Team"
            heading="What we do"
            text1="We provide solutions capable to run in production for the life of the control system."
            text2="Whether you are designing or starting up a new production facility or upgrading your existing control system, we can assist you with application design, testing and commissioning to ensure you meet your production demand."
            image="../static/images/gas-boiler.jpg"
            alttext="gas boiler pipes and instruments"
            leftallign={false}
          />
        </Row>
      </PageContent>
    </>
  );
};

export default Home;
