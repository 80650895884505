import React, { ReactNode } from "react";
import classes from "./PageContent.module.css";

const PageContent: React.FC<{
  title: string;
  heading: string;
  image: string;
  children: ReactNode;
}> = (props: {
  title: string;
  heading: string;
  image: string;
  children: ReactNode;
}) => {
  return (
    <>
      <div
        className={classes.image}
        style={{
          backgroundImage: `url(${process.env.PUBLIC_URL + props.image})`,
          backgroundRepeat: "no-repeat",
        }}
      >
        <div>
          <h1 className={classes.h1}>{props.title}</h1>
          <h6 className={classes.h6}>{props.heading}</h6>
        </div>
      </div>
      <div className={classes.content}>{props.children}</div>
    </>
  );
};

export default PageContent;
