import { Col, Row } from "react-bootstrap";
import classes from "./HomeDescription.module.css";

const HomeDescription: React.FC<{
  label: string;
  heading: string;
  text1: string;
  text2: string;
  image: string;
  alttext: string;
  leftallign: boolean;
}> = (props: {
  label: string;
  heading: string;
  text1: string;
  text2: string;
  image: string;
  alttext: string;
  leftallign: boolean;
}) => {
  if (props.leftallign === true) {
    return (
      <>
        <Row xs={1} md={3} className="g-4" key="firstrow">
          <Col>
            <div className={classes.label}>{props.label}</div>
            <div className={classes.heading}>{props.heading}</div>
            <div className={classes.paragraph}>
              <div className={classes.text}>{props.text1}</div>
              <div className={classes.text}>{props.text2}</div>
            </div>
          </Col>
          <Col>
            <div>
              <img
                src={props.image}
                className={`${classes.right} rounded-3 shadow-4`}
                alt={props.alttext}
              ></img>
            </div>
          </Col>
        </Row>
      </>
    );
  } else {
    return (
      <>
        <Row>
          <Col>
            <div>
              <img
                src={props.image}
                className={`${classes.left} rounded-3 shadow-4`}
                alt={props.alttext}
              ></img>
            </div>
          </Col>
          <Col>
            <div className={classes.label}>{props.label}</div>
            <div className={classes.heading}>{props.heading}</div>
            <div>
              <div className={classes.text}>{props.text1}</div>
              <div className={classes.text}>{props.text2}</div>
            </div>
          </Col>
        </Row>
      </>
    );
  }
};

export default HomeDescription;
