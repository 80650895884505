import { useEffect } from "react";

function useDocumentTitle(title: string) {
  var documentTitle = document.getElementById("title") as HTMLTitleElement;

  useEffect(() => {
    documentTitle.text = title;
  }, [documentTitle, title]);
}

export default useDocumentTitle;
